const Types = {
  //auth
  GET_USER_DATA:"GET_USER_DATA",
  CLEAR_GET_USER_DATA:"CLEAR_GET_USER_DATA",
  LOGIN:"LOGIN",
  CLEAR_LOGIN:"CLEAR_LOGIN",
  LOGOUT:"LOGOUT",

  //overview
  GET_OVERVIEW_DATA:"GET_OVERVIEW_DATA",
  CLEAR_GET_OVERVIEW_DATA:"CLEAR_GET_OVERVIEW_DATA",
  GET_OVERVIEW_DATA_SYNC:"GET_OVERVIEW_DATA_SYNC",
  IS_GET_OVERVIEW_DATA_ERROR:"IS_GET_OVERVIEW_DATA_ERROR",

  //readers summary
  GET_READERS_SUMMARY_CARDS: "GET_READERS_SUMMARY_CARDS",
  GET_READERS_SUMMARY_CARDS_LOADING: "GET_READERS_SUMMARY_CARDS_LOADING",
  GET_READERS_SUMMARY_CARDS_ERROR: "GET_READERS_SUMMARY_CARDS_ERROR",
  CLEAR_GET_READERS_SUMMARY_CARDS: "CLEAR_GET_READERS_SUMMARY_CARDS",
  GET_READERS_SUMMARY:"GET_READERS_SUMMARY",
  CLEAR_GET_READERS_SUMMARY:"CLEAR_GET_READERS_SUMMARY",
  GET_READERS_SUMMARY_EDIT_CARDS: "GET_READERS_SUMMARY_EDIT_CARDS",
  GET_READERS_SUMMARY_EDIT_CARDS_LOADING: "GET_READERS_SUMMARY_EDIT_CARDS_LOADING",
  GET_READERS_SUMMARY_EDIT_CARDS_ERROR: "GET_READERS_SUMMARY_EDIT_CARDS_ERROR",
  CLEAR_GET_READERS_SUMMARY_EDIT_CARDS: "CLEAR_GET_READERS_SUMMARY_EDIT_CARDS",
  UPDATE_READERS_SUMMARY: "UPDATE_READERS_SUMMARY",
  UPDATE_READERS_SUMMARY_LOADING: "UPDATE_READERS_SUMMARY_LOADING",
  UPDATE_READERS_SUMMARY_ERROR: "UPDATE_READERS_SUMMARY_ERROR",
  CLEAR_UPDATE_READERS_SUMMARY: "CLEAR_UPDATE_READERS_SUMMARY",
  DELETE_READERS_SUMMARY_CARD: "DELETE_READERS_SUMMARY_CARD",
  //reaaders summary house
  GET_READERS_SUMMARY_HOUSE_CARDS: "GET_READERS_SUMMARY_HOUSE_CARDS",
  GET_READERS_SUMMARY_HOUSE_CARDS_LOADING: "GET_READERS_SUMMARY_HOUSE_CARDS_LOADING",
  GET_READERS_SUMMARY_HOUSE_CARDS_ERROR: "GET_READERS_SUMMARY_HOUSE_CARDS_ERROR",
  CLEAR_GET_READERS_SUMMARY_HOUSE_CARDS: "CLEAR_GET_READERS_SUMMARY_HOUSE_CARDS",
  GET_READERS_SUMMARY_HOUSE_EDIT_CARDS: "GET_READERS_SUMMARY_HOUSE_EDIT_CARDS",
  GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_LOADING: "GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_LOADING",
  GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_ERROR: "GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_ERROR",
  CLEAR_GET_READERS_SUMMARY_HOUSE_EDIT_CARDS: "CLEAR_GET_READERS_SUMMARY_HOUSE_EDIT_CARDS",
  UPDATE_READERS_SUMMARY_HOUSE: "UPDATE_READERS_SUMMARY_HOUSE",
  DELETE_READERS_SUMMARY_HOUSE_CARD: "DELETE_READERS_SUMMARY_HOUSE_CARD",

  //farm details
  GET_FARM_DETAILS:"GET_FARM_DETAILS",
  CLEAR_GET_FARM_DETAILS:"CLEAR_GET_FARM_DETAILS",

  //app data
  SET_DAY_SELECTED:"SET_DAY_SELECTED",
  CLEAR_SET_DAY_SELECTED:"CLEAR_SET_DAY_SELECTED",
  SET_MODULE_DATA_SELECTED:"SET_MODULE_DATA_SELECTED",
  CLEAR_SET_MODULE_DATA_SELECTED:"CLEAR_SET_MODULE_DATA_SELECTED",
  SET_MODULE_SELECTED:"SET_MODULE_SELECTED",
  CLEAR_SET_MODULE_SELECTED:"CLEAR_SET_MODULE_SELECTED",
  SET_IS_COMULTIVE:"SET_IS_COMULTIVE",
  CLEAR_SET_IS_COMULTIVE:"CLEAR_SET_IS_COMULTIVE",
  SHOW_INFO_MSG_HOME:"SHOW_INFO_MSG_HOME",
  STOP_SCROLLING:"STOP_SCROLLING",
  CLEAR_STOP_SCROLLING:"CLEAR_STOP_SCROLLING",
  START_TOUR_POPUP:"START_TOUR_POPUP",
  CLEAR_START_TOUR_POPUP:"CLEAR_START_TOUR_POPUP",
  SET_NOTIFICATIONS:"SET_NOTIFICATIONS",
  CLEAR_SET_NOTIFICATIONS:"CLEAR_SET_NOTIFICATIONS",
  IS_GET_DATA_ERROR:"IS_GET_DATA_ERROR",

  //joy ride
  START_TOUR:"START_TOUR",
  CLEAR_START_TOUR:"CLEAR_START_TOUR",
};

export default Types;
