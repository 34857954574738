import React, { useEffect, useState } from 'react'
import { Fragment } from 'react';
import { connect } from "react-redux";

import {
    STOP_SCROLLING,
} from "../../redux/actions/appData/index"

import HomePageReadersSummaryCard from './HomePageReadersSummaryCard';
import HomePageReadersSummaryEditPopup from './HomePageReadersSummaryEditPopup';

import { ReactComponent as PencilSvg } from "../../assets/pencil.svg";
import { ReactComponent as DeleteSvg } from "../../assets/delete.svg";

function HomePageReadersSummary({ data, moduleSelected, STOP_SCROLLING, monthly }) {

    const [isPopUpOpen, setIsPopUpOpen] = useState(false)

    useEffect(() => {
        if (isPopUpOpen) {
            STOP_SCROLLING(true)
        } else {
            STOP_SCROLLING(false)
        }
    }, [isPopUpOpen])

    const [openDeleteButtons, setOpenDeleteButtons] = useState(false)

    return (
        <Fragment>
            {data?.length > 0 && (
                <div className="home-page__top__details" id="tour-home-page-details-cards-scrollx-top">
                    {data.map((field, index) => {
                        return <HomePageReadersSummaryCard field={field} index={index} moduleSelected={moduleSelected}
                            key={index} openDeleteButtons={openDeleteButtons} monthly={monthly} />
                    })}
                    {/* <div className="home-page__top__details__options">
                        <div className="home-page__top__details__options__option" onClick={()=>setIsPopUpOpen(true)}><PencilSvg /></div>
                        <div className="home-page__top__details__options__option" onClick={()=>setOpenDeleteButtons(!openDeleteButtons)}><DeleteSvg /></div>
                    </div> */}
                </div>
            )}
            <HomePageReadersSummaryEditPopup isPopUpOpen={isPopUpOpen} setIsPopUpOpen={setIsPopUpOpen} />
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    STOP_SCROLLING,
})(React.memo(HomePageReadersSummary));