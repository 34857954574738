import React, { Fragment, useEffect, useState } from 'react'
import { ReactComponent as FilterSvg } from "../../assets/filter.svg";
import { ReactComponent as CheckedSvg } from "../../assets/checked.svg";
import { ReactComponent as CanceldSvg } from "../../assets/cancel.svg";
import { connect } from "react-redux";

import {
    CLEAR_START_TOUR_POPUP,
} from "../../redux/actions/appData/index";

import {
    START_TOUR,
} from "../../redux/actions/tour/index";

function StartTourPopUp(props) {

    const closePopup = () => {
        props.setIsPopUpOpen(false)
        props.CLEAR_START_TOUR_POPUP();
    }

    const startTheTour = () => {
        if (props.gstate.tour == true) {
            props.START_TOUR(false)
            setTimeout(() => {
                props.START_TOUR(true)
            }, 100);
        } else {
            props.START_TOUR(true)
        }

        props.setIsPopUpOpen(false)
        props.CLEAR_START_TOUR_POPUP();
    }

    return (
        <Fragment>
            <div className="overlay-popup" style={!props.isPopUpOpen ? { display: "none" } : {zIndex: "119"}} />
            <div className="day-popup" style={!props.isPopUpOpen ? { display: "none" } : {zIndex: "120"}}>
                <div className="day-popup__title">
                    <span>Start the tour now!</span>
                </div>

                <div className="day-popup__text">
                    <span>Go through a tour to know how to navigate the application easily.</span>
                </div>

                <div className="day-popup__btns">
                    <div className="day-popup__btns__btn-reset" onClick={closePopup}>
                        <CanceldSvg />
                        <span>cancel</span>
                    </div>
                    <div className="day-popup__btns__btn-apply" onClick={startTheTour}>
                        <CheckedSvg />
                        <span>Start</span>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    CLEAR_START_TOUR_POPUP,
    START_TOUR,
})(StartTourPopUp);