import React from 'react'
import { ReactComponent as AbuErdanLogoSvg } from "../../assets/svg-modules-icon/logo.svg";

function FullPageNoData() {
    return (
        <div className="fullpage-nodata">
            <span>No data</span>
            <AbuErdanLogoSvg />
        </div>
    )
}

export default FullPageNoData
