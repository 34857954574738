import React, { Fragment, useEffect, useState } from 'react'
import NavBar from '../../layouts/navBar/NavBar'
import { Switch } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from "react-redux";

import {
    SET_MODULE_DATA_SELECTED,
} from "../../redux/actions/appData/index"

import {
    GET_FARM_DETAILS,
    CLEAR_GET_FARM_DETAILS,
} from "../../redux/actions/farmDetails/index"

import basicLineChartDataGenerator from "../../helpers/chartGenerators/basicLineChartDataGenerator";
import barChartDataGenerator from "../../helpers/chartGenerators/barChartDataGenerator";
import HousesDataField from './HousesDataField';
import BreedersHousesDataField from './BreedersHousesDataField';
import FullPageSpinner from '../../layouts/spinners/FullPageSpinner';
import FarmDetailsReadersSummary from './FarmDetailsReadersSummary';
import NoInternet from '../../layouts/noInternet/NoInternet';
import FullPageError from '../../layouts/handleError/FullPageError';

import { ReactComponent as DollarSvg } from "../../assets/dollar-symbol.svg";
import LayersProductionHouses from "./LayersProductionHouses";

import TablesContainer from '../tables/TablesContainer';
import ErrorBoundary from "../../helpers/ErrorBoundaries";

const AgesTable = React.lazy(() => import("../tables/AgesTable"));
const MaleFemaleChartTable = React.lazy(() => import("../tables/MaleFemaleChartTable"));

function FarmDetails(props) {

    const isCum = localStorage.getItem('isCum')

    useEffect(() => {
        props.CLEAR_GET_FARM_DETAILS()
        //get overview data every time isCum changes and this if to not send the API 2 times with old idCum value and new one
        if (isCum) {
            props.GET_FARM_DETAILS(props.match.params.module, props.match.params.day, props.match.params.id, JSON.parse(isCum))
        } else {
            props.GET_FARM_DETAILS(props.match.params.module, props.match.params.day, props.match.params.id, props.gstate.isComultive)
        }
    }, [props.gstate.moduleSelected, props.gstate.daySelected, props.match.params.day, props.match.params.id, props.match.params.module, props.gstate.isComultive])


    useEffect(() => {
        if (!localStorage.getItem("toggleFarmLineChartTooltip")) {
            localStorage.setItem("toggleFarmLineChartTooltip", JSON.stringify({ breeders: [], hatchery: [], broilers: [], layers: [], slaughterhouse: [] }))
        }
    }, [])


    //charts
    const [charts, setCharts] = useState([])
    const [toggleLineChartTooltip, setToggleLineChartTooltip] = useState([]);

    let moduleName
    if (props.gstate.moduleSelected) {
        moduleName = props.gstate.moduleSelected.toLowerCase()
    } else {
        moduleName = props.match?.params?.module.toLowerCase()
    }

    const toggleLineChartTooltipHandler = async (index, value, title) => {
        let toggleLineChartTooltipArr = [...toggleLineChartTooltip]
        toggleLineChartTooltipArr[index] = value
        await setToggleLineChartTooltip(toggleLineChartTooltipArr)
        const cachedToggleLineChartTooltip = JSON.parse(localStorage.getItem("toggleFarmLineChartTooltip"))
        let newValue = cachedToggleLineChartTooltip
        if (moduleName && title && cachedToggleLineChartTooltip && cachedToggleLineChartTooltip[moduleName]) {
            if (cachedToggleLineChartTooltip[moduleName].length > 0) {
                const itemIndex = cachedToggleLineChartTooltip[moduleName].findIndex(chart => chart === title)
                if (itemIndex >= 0 && !value) {
                    newValue[moduleName].splice(itemIndex, 1)
                    localStorage.setItem("toggleFarmLineChartTooltip", JSON.stringify(newValue))
                } else if (!itemIndex >= 0 && value) {
                    newValue[moduleName].push(title)
                    localStorage.setItem("toggleFarmLineChartTooltip", JSON.stringify(newValue))
                }
            } else {
                newValue[moduleName].push(title)
                localStorage.setItem("toggleFarmLineChartTooltip", JSON.stringify(newValue))
            }
        }
        let chartsArr = [...charts]
        chartsArr[index].chart = basicLineChartDataGenerator(props.gstate.farmDetails.charts[index], toggleLineChartTooltipArr, index)
        setCharts(chartsArr)
    }


    useEffect(() => {
        if (props.gstate.farmDetails?.charts?.length > 0) {
            setToggleLineChartTooltip([])
            let chartArr = []
            let toggleLineChartTooltipArr = []
            const cachedToggleLineChartTooltip = JSON.parse(localStorage.getItem("toggleFarmLineChartTooltip"))
            props.gstate.farmDetails.charts.map((chart, index) => {
                if (cachedToggleLineChartTooltip[moduleName]?.length > 0 && cachedToggleLineChartTooltip[moduleName].find(item => item === chart.title)) {
                    toggleLineChartTooltipArr.push(true);
                } else {
                    toggleLineChartTooltipArr.push(false);
                }
                chartArr.push({
                    unit: chart.unit,
                    title: chart.title,
                    chart: basicLineChartDataGenerator(chart, toggleLineChartTooltipArr, index),
                    table: chart.data
                })
            })
            setCharts(chartArr);
            toggleLineChartTooltip.length === 0 && setToggleLineChartTooltip(toggleLineChartTooltipArr);
        }
    }, [props.gstate.farmDetails])

    useEffect(() => {
        return () => {
            setCharts([])
            props.CLEAR_GET_FARM_DETAILS()
        }
    }, [])

    //age chart
    const [ageChart, setAgeChart] = useState()

    const [ageChartByWeeks, setageChartByWeeks] = useState(false)

    useEffect(() => {
        if (props.gstate.farmDetails && props.gstate.farmDetails.age_chart) {
            let chartData = barChartDataGenerator(props.gstate.farmDetails.age_chart.labels, JSON.stringify(props.gstate.farmDetails.age_chart.age), ageChartByWeeks)
            setAgeChart(chartData)
        }
    }, [props.gstate.farmDetails, ageChartByWeeks])

    //houses in breeders grouped by breeds 
    const [breeds, setBreeds] = useState([])

    useEffect(() => {
        if (props.gstate.farmDetails && props.gstate.farmDetails.breeders_houses) {
            setBreeds(Object.keys(props.gstate.farmDetails.breeders_houses))
        }
    }, [props.gstate.farmDetails])


    return (
        <Fragment>
            <NavBar atDetails={true} moduleName={["Breeders", "Hatchery", "Broilers", "Layers"].includes(props.match.params.module) ? props.match.params.module : null} />
            {props.gstate.farmDetails ? (
                <div className="farm-details">
                    <div className="farm-details__header">
                        <div className="farm-details__header__title general-container">
                            <div className="farm-details__header__title__text">
                                <b>{props.gstate.farmDetails?.farm_name}</b>
                                {props.gstate.farmDetails?.is_selling && (
                                    <div className="farm-details__header__title__text__selling">Selling</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <FarmDetailsReadersSummary data={props.gstate.farmDetails?.data} moduleSelected={moduleName} matchParams={props.match.params} />
                    <div className="general-container">
                        <div className="farm-details__body">
                            {props.gstate.farmDetails?.houses?.length > 0 && <HousesDataField houses={props.gstate.farmDetails.houses} moduleSelected={props.match?.params?.module} />}

                            {props.gstate.farmDetails?.breeders_houses && <BreedersHousesDataField breeds={breeds} houses={props.gstate.farmDetails?.breeders_houses} moduleSelected={props.match?.params?.module} />}
                            {props.gstate.farmDetails?.houses_production?.length > 0 && <LayersProductionHouses houses={props.gstate.farmDetails?.houses_production} />}

                            {ageChart && (
                                <div className="farm-details__body__chart remove-highchart-legend">
                                    <div className="farm-details__body__chart__head">
                                        <div className="farm-details__body__chart__head__title" style={{ margin: "0" }}>Houses Ages</div>
                                        <div className="home-page__top__charts__head__weeksOrDays">
                                            <button className={ageChartByWeeks ? "btn home-page__top__charts__head__weeksOrDays__not-active" : "btn home-page__top__charts__head__weeksOrDays__active"} onClick={() => setageChartByWeeks(false)}>days</button>
                                            <button className={ageChartByWeeks ? "btn home-page__top__charts__head__weeksOrDays__active" : "btn home-page__top__charts__head__weeksOrDays__not-active"} onClick={() => setageChartByWeeks(true)}>weeks</button>
                                        </div>
                                    </div>
                                    <div className="farm-details__body__chart__cont">
                                        <HighchartsReact highcharts={Highcharts} options={ageChart} />
                                        <TablesContainer>
                                            <ErrorBoundary>
                                                <AgesTable data={ageChart} moduleSelected={props.gstate.moduleSelected} />
                                            </ErrorBoundary>
                                        </TablesContainer>
                                    </div>
                                </div>
                            )}

                            {charts.length > 0 && (
                                <div className="farm-details__body__chart">
                                    {charts.map((chart, index) => {
                                        return (
                                            <div className="farm-details__body__line-chart" key={index}>
                                                <div className="farm-details__body__chart__head" style={{ justifyContent: "space-between" }}>
                                                    <div className="farm-details__body__chart__head__title" style={{ margin: "0" }}>{chart.title}</div>
                                                    <div className='home-page__top__charts__head__tooltip'>
                                                        <div className='home-page__top__charts__head__tooltip__title'>Tooltip: </div>
                                                        <Switch
                                                            checkedChildren="Single field"
                                                            unCheckedChildren="All fields"
                                                            checked={toggleLineChartTooltip.length > 0 && toggleLineChartTooltip[index]}
                                                            onChange={value => toggleLineChartTooltipHandler(index, value, chart.title)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="home-page__top__charts__cont" style={{ marginBottom: "2rem" }}>
                                                    <HighchartsReact highcharts={Highcharts} options={chart.chart} />
                                                     {(props.gstate.moduleSelected === "Breeders" || props.match?.params?.module === "Breeders" || props.gstate.moduleSelected === "Layers" || props.match?.params?.module === "Layers") && (
                                                        <TablesContainer>
                                                            <ErrorBoundary>
                                                                <MaleFemaleChartTable data={chart.table} unit={chart.unit} />
                                                            </ErrorBoundary>
                                                        </TablesContainer>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (window.navigator.onLine ? (props.gstate.getDataError ? <FullPageError day={props.gstate.daySelected?.value} isCum={props.gstate.isComultive} /> : <FullPageSpinner />)
                : <NoInternet />)}

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    SET_MODULE_DATA_SELECTED,
    GET_FARM_DETAILS,
    CLEAR_GET_FARM_DETAILS,
})(FarmDetails);




////////////////////////////////////////////////////////

// import React, { Fragment, useEffect, useState } from 'react'
// import NavBar from '../../layouts/navBar/NavBar'
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import { connect } from "react-redux";

// import {
//     SET_MODULE_DATA_SELECTED,
// } from "../../redux/actions/appData/index"


// import columnChartDataGenerator from "../homePage/columnChartDataGenerator";
// import barChartDataGenerator from "../homePage/barChartDataGenerator";
// import HousesDataField from './HousesDataField';


// function FarmDetails(props) {

//     useEffect(() => {
//         if (props.gstate.farmDetails) {
//             //fire the get module details action
//         }
//     }, [props.gstate.farmDetails, props.gstate.daySelected])

//     //charts
//     const [charts, setCharts] = useState([])

//     useEffect(() => {
//         if (props.gstate.farmDetails?.charts?.length > 0) {
//             let chartArr = []
//             props.gstate.farmDetails.charts.map((chart, index) => {
//                 if (chart.has_toggle) {
//                     chartArr.push({
//                         toggle: true,
//                         unit: chart.unit,
//                         unitToggled: chart.unit_toggle,
//                         title: chart.chart_title,
//                         titleToggled: chart.chart_title_toggle,
//                         chart: columnChartDataGenerator(chart.data, chart.standard, chart.labels, chart.unit),
//                         chartToggled: columnChartDataGenerator(chart.data_toggle, chart.standard, chart.labels, chart.unit_toggle),
//                     })
//                 } else {
//                     chartArr.push({
//                         toggle: false,
//                         unit: chart.unit,
//                         title: chart.chart_title,
//                         chart: columnChartDataGenerator(chart.data, chart.standard, chart.labels, chart.unit)
//                     })
//                 }
//             })
//             setCharts(chartArr);
//         }
//     }, [props.gstate.farmDetails])

//     const toggleChart = (index) => {
//         let newArr = [...charts]

//         let title = charts[index].titleToggled
//         let toggleTitle = charts[index].title
//         let unit = charts[index].unitToggled
//         let toggleUnit = charts[index].unit
//         let chart = JSON.stringify(charts[index].chartToggled)
//         let toggleChart = JSON.stringify(charts[index].chart)

//         newArr[index].title = title
//         newArr[index].titleToggled = toggleTitle
//         newArr[index].unit = unit
//         newArr[index].unitToggled = toggleUnit
//         newArr[index].chart = JSON.parse(chart)
//         newArr[index].chartToggled = JSON.parse(toggleChart)

//         setCharts(newArr)
//     }

//     //age chart
//     const [ageChart, setAgeChart] = useState()

//     const [ageChartByWeeks, setageChartByWeeks] = useState(false)

//     useEffect(() => {
//         if (props.gstate.farmDetails && props.gstate.farmDetails.age_chart) {
//             let chartData = barChartDataGenerator(props.gstate.farmDetails.age_chart.labels, JSON.stringify(props.gstate.farmDetails.age_chart.age), ageChartByWeeks)
//             setAgeChart(chartData)
//         }
//     }, [props.gstate.farmDetails, ageChartByWeeks])

//     return (
//         <Fragment>
//             <NavBar atDetails={true} />
//             <div className="farm-details">
//                 <div className="farm-details__header">
//                     <div className="farm-details__header__title general-container">
//                         <div className="farm-details__header__title__text">
//                             {props.gstate.farmDetails.farm_name}
//                         </div>
//                     </div>
//                 </div>
//                 <div className="general-container">
//                     <div className="farm-details__body">
//                         {props.gstate.farmDetails?.houses?.length > 0 && <HousesDataField houses={props.gstate.farmDetails.houses} />}

//                         {ageChart && (
//                             <div className="farm-details__body__chart remove-highchart-legend">
//                                 <div className="farm-details__body__chart__head">
//                                     <div className="farm-details__body__chart__head__title" style={{ margin: "0" }}>Farms ages</div>
//                                     <div className="home-page__top__charts__head__weeksOrDays">
//                                         <button className={ageChartByWeeks ? "btn home-page__top__charts__head__weeksOrDays__not-active" : "btn home-page__top__charts__head__weeksOrDays__active"} onClick={() => setageChartByWeeks(false)}>days</button>
//                                         <button className={ageChartByWeeks ? "btn home-page__top__charts__head__weeksOrDays__active" : "btn home-page__top__charts__head__weeksOrDays__not-active"} onClick={() => setageChartByWeeks(true)}>weeks</button>
//                                     </div>                                </div>
//                                 <div className="farm-details__body__chart__cont">
//                                     <HighchartsReact highcharts={Highcharts} options={ageChart} />
//                                 </div>
//                             </div>
//                         )}

//                         {charts.length > 0 && (
//                             <div className="farm-details__body__chart">
//                                 {charts.map((chart, index) => {
//                                     return (
//                                         chart.toggle ? (
//                                             <div key={index}>
//                                                 <div className="farm-details__body__chart__head">
//                                                     <div className="farm-details__body__chart__head__switch">
//                                                         <label class="switch">
//                                                             <input
//                                                                 type="checkbox"
//                                                                 id={`chartToggleCheckboxDetails${index}`}
//                                                                 onChange={() => toggleChart(index)}
//                                                             />
//                                                             <span class="slider round"></span>
//                                                         </label>
//                                                     </div>
//                                                     <div className="farm-details__body__chart__head__title">{chart.title}</div>
//                                                 </div>
//                                                 <div className="home-page__top__charts__cont">
//                                                     <HighchartsReact highcharts={Highcharts} options={chart.chart} />
//                                                 </div>
//                                             </div>
//                                         ) : (
//                                             <div key={index}>
//                                                 <div className="farm-details__body__chart__head">
//                                                     <div className="farm-details__body__chart__head__title">{chart.title}</div>
//                                                 </div>
//                                                 <div className="home-page__top__charts__cont">
//                                                     <HighchartsReact highcharts={Highcharts} options={chart.chart} />
//                                                 </div>
//                                             </div>
//                                         )
//                                     )
//                                 })}
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </Fragment>
//     )
// }

// const mapStateToProps = (state) => {
//     return { gstate: state };
// };

// export default connect(mapStateToProps, {
//     SET_MODULE_DATA_SELECTED,
// })(FarmDetails);