import { Button } from 'antd'
import React from 'react'

function ErrorFallback() {
    return (
        <div className='error-fallback'>
            <h3>This application has been updated, please refresh and try again</h3>
            <Button onClick={() => window.location.reload()}>Refresh</Button>
        </div>
    )
}

export default ErrorFallback
