import React, { Fragment, useEffect, useState } from 'react'
import FarmCard from './FarmCard'
import _ from "lodash";
import HatcheryFarmCard from "./HatcheryFarmCard";
import { ReactComponent as SortSvg } from "../../assets/descendant.svg";
import Dropdown from 'react-dropdown';


function HatcheryFarmsDataField({ farms , monthly_farms , farms_hatchery_data , isCum }) {
    function handleChange(value) {
        if (value.value == "Sort by age") {
            filterByAge()
        } else if (value.value == "Sort by weight") {
            filterByWight()
        } else if (value.value == "Alpha a-z") {
            filterByAlpha()
        } else {
            filterByAge()
        }
    }

    function handle_recieved_Change(value) {
        if (value.value == "Sort by age") {
            filter_recevied_ByAge()
        } else if (value.value == "Sort by weight") {
            filter_recevied_ByWight()
        } else if (value.value == "Alpha a-z") {
            filter_recevied_ByAlpha()
        } else {
            filter_recevied_ByAge()
        }
    }

    function handle_monthly_Change(value) {
        if (value.value == "Sort by age") {
            filter_monthly_ByAge()
        } else if (value.value == "Sort by weight") {
            filter_monthly_ByWight()
        } else if (value.value == "Alpha a-z") {
            filter_monthly_ByAlpha()
        } else {
            filter_monthly_ByAge()
        }
    }

    const options = [
        'Sort by age', 'Alpha a-z'
    ];

    const [filteredData, setFilteredData] = useState()
    const [receivedfilteredData, setreceivedfilteredData] = useState()
    const [monthlyFilteredData, setMonthlyFilteredData] = useState()

    const filterByAge = () => {
        setFilteredData(
            _.sortBy(farms, [
                function (farm) {
                    return farm.age_in_days;
                },
            ]).reverse()
        );
    }

    const filter_recevied_ByAge = () => {
        setreceivedfilteredData(
            _.sortBy(farms_hatchery_data, [
                function (farm) {
                    return farm.age_in_days;
                },
            ]).reverse()
        );
    }

    const filter_monthly_ByAge = () => {
        setMonthlyFilteredData(
            _.sortBy(monthly_farms, [
                function (farm) {
                    return farm.age_in_days;
                },
            ]).reverse()
        );
    }

    const filter_monthly_ByWight = () => {
        setMonthlyFilteredData(
            _.sortBy(monthly_farms, [
                function (farm) {
                    return farm.weight;
                },
            ]).reverse()
        );
    }

    const filter_monthly_ByAlpha = () => {
        setMonthlyFilteredData(
            _.sortBy(monthly_farms, [
                function (farm) {
                    return farm.name;
                },
            ]).reverse()
        );
    }

    const filter_recevied_ByWight = () => {
        setreceivedfilteredData(
            _.sortBy(farms_hatchery_data, [
                function (farm) {
                    return farm.weight;
                },
            ]).reverse()
        );
    }

    const filter_recevied_ByAlpha = () => {
        setreceivedfilteredData(
            _.sortBy(farms_hatchery_data, [
                function (farm) {
                    return farm.name;
                },
            ]).reverse()
        );
    }

    const filterByWight = () => {
        setFilteredData(
            _.sortBy(farms, [
                function (farm) {
                    return farm.weight;
                },
            ]).reverse()
        );
    }

    const filterByAlpha = () => {
        setFilteredData(
            _.sortBy(farms, [
                function (farm) {
                    return farm.name;
                },
            ])
        );
    }

    useEffect(() => {
        if (farms) {
            setFilteredData(
                _.sortBy(farms, [
                    function (farm) {
                        return farm.age_in_days;
                    },
                ]).reverse()
            );
        }

        if (farms_hatchery_data) {
            setreceivedfilteredData(
                _.sortBy(farms_hatchery_data, [
                    function (farm) {
                        return farm.age_in_days;
                    },
                ]).reverse()
            );
        }

        if (monthly_farms) {
            setMonthlyFilteredData(
                _.sortBy(monthly_farms, [
                    function (farm) {
                        return farm.age_in_days;
                    },
                ]).reverse()
            );
        }


    }, [farms , farms_hatchery_data, monthly_farms])


    return (
        <Fragment>
        { (!(isCum) && receivedfilteredData?.length > 0) &&  (
            <>
            <div className="home-page__top__title">
                Output Chicks

                <div className="home-page__top__title__dropdown">
                    <Dropdown options={options} onChange={handle_recieved_Change} value={options[0]} placeholder="Select an option" />
                </div>
            </div>

            <div className="home-page__top__cards">
                {receivedfilteredData?.length > 0 && receivedfilteredData.map((farm_hatchery_data, index) => {
                    return <HatcheryFarmCard data={farm_hatchery_data} key={index} />
                })}
            </div>
            </>
        )}

        { filteredData?.length > 0 && (
            <>
                <div className="home-page__top__title">
                Internal Farms Performance

                <div className="home-page__top__title__dropdown">
                    <Dropdown options={options} onChange={handleChange} value={options[0]} placeholder="Select an option" />
                </div>
                </div>
                <div className="home-page__top__cards">
                {filteredData?.length > 0 && filteredData.map((farm, index) => {
                    return <HatcheryFarmCard data={farm} key={index} />
                })}
                </div>
            </>
        )}

        { ((monthlyFilteredData?.length > 0) && (isCum)) && (
            <>
                <div className="home-page__top__title">
                Hatchery Activities (Month to Date)

                <div className="home-page__top__title__dropdown">
                    <Dropdown options={options} onChange={handle_monthly_Change} value={options[0]} placeholder="Select an option" />
                </div>
                </div>
                <div className="home-page__top__cards">
                {monthlyFilteredData?.length > 0 && monthlyFilteredData.map((farm, index) => {
                    return <HatcheryFarmCard data={farm} key={index} />
                })}
                </div>
            </>
        )}

        </Fragment>
    )
}

export default React.memo(HatcheryFarmsDataField);