import React, { Fragment } from 'react'
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import SecureLS from "secure-ls";


import {
    START_TOUR,
} from "../../redux/actions/tour/index";

import {
    STOP_SCROLLING,
} from "../../redux/actions/appData/index"

import {
    LOGIN,
    LOGOUT,
} from "../../redux/actions/registration/index"

import {
    GET_OVERVIEW_DATA_SYNC,
    GET_OVERVIEW_DATA,
    CLEAR_GET_OVERVIEW_DATA
} from "../../redux/actions/overView/index";

import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as AbuErdanLogoSvg } from "../../assets/svg-modules-icon/logo.svg";

import { ReactComponent as HomeSvg } from "../../assets/home.svg";
import { ReactComponent as DashboardSvg } from "../../assets/dashboard.svg";
import { ReactComponent as SettingSvg } from "../../assets/settings.svg";
import { ReactComponent as NotificationBSvg } from "../../assets/bellSideBar.svg";
import { ReactComponent as LogoutSvg } from "../../assets/logout.svg";
import { ReactComponent as LangSvg } from "../../assets/global.svg";
import { ReactComponent as MapSvg } from "../../assets/map.svg";
import { ReactComponent as ReportSvg } from "../../assets/chart-report.svg";
import { ReactComponent as RefreshSvg } from "../../assets/refresh-page-option.svg";
import { ReactComponent as ContrastSvg } from "../../assets/contrast.svg";
import { ReactComponent as EnvelopeSvg } from "../../assets/envelope.svg";


import useImg from "../../assets/user.png"
import { Link } from 'react-router-dom';

function SideNavMenu(props) {

    const startTour = () => {
        if (props.runTour == true) {
            props.START_TOUR(false)
            setTimeout(() => {
                props.START_TOUR(true)
            }, 100);
        } else {
            props.START_TOUR(true)
        }
    }

    const logout = () => {
        props.LOGIN(false)
        window.localStorage.removeItem('UT');
        props.LOGOUT()
        window.localStorage.removeItem('isCum');
        window.localStorage.removeItem('daySelected');
        window.localStorage.removeItem('notifications');
    }

    const updateTheData = () => {
        props.GET_OVERVIEW_DATA_SYNC(props.isCum, props.CLEAR_GET_OVERVIEW_DATA, props.GET_OVERVIEW_DATA)
    }

    let themeNotSelect = "Dark mode"

    if(localStorage.getItem("theme") && localStorage.getItem("theme") === "dark") {
        themeNotSelect = "Light mode"
    }

    const switchTheme = () => {
        if(localStorage.getItem("theme") === "dark") {
            localStorage.setItem("theme", "light");
            window.location.reload();
        } else {
            localStorage.setItem("theme", "dark");
            window.location.reload();
        }
    }
    var ls = new SecureLS({ encodingType: 'des', isCompression: true, encryptionSecret: '$_!exEcultive-aPp@data*2021.aBuerdAn$$' });
    let email = ls.get('EM')?.data;
    let userUrl = window.localStorage.getItem('userUrl');
    let exec_link = null

    if (userUrl != "altahoneh.abuerdan.com") {
    exec_link = <a className="sideNavMenu__body__content__nav-tabs__tab"
    href={"https://" +userUrl + "/commonreports/new_exec_app_summary_report?em=" + email }
    >
                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><ReportSvg /></div>
                <div className="sideNavMenu__body__content__nav-tabs__tab__name">Executive Report</div></a>
    }

    return (
        <Fragment>
            <div className="overlay" onClick={() => { props.setOpenSideNavMenu(false) }}
                style={!props.openSideNavMenu ? { display: "none" } : {}} />
            <div className="sideNavMenu">
                <div className="sideNavMenu__head">
                    <div className="sideNavMenu__head__close" onClick={() => { props.setOpenSideNavMenu(false) }}>
                        <CloseSvg />
                    </div>
                </div>

                <div className="sideNavMenu__body">
                    <div className="sideNavMenu__body__content">
                        <div className="sideNavMenu__body__content__img">
                            <ReactImageFallback
                                src={props.profile?.image_url}
                                fallbackImage={useImg}
                                initialImage={useImg}
                                alt="user image"
                            />
                        </div>
                        <div className="sideNavMenu__body__content__name">{props.profile?.name}</div>
                        <div className="sideNavMenu__body__content__sub">{props.profile?.email}</div>
                        <div className="sideNavMenu__body__content__line" />
                        {/* <div className="sideNavMenu__body__content__desc">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry
                        </div> */}
                        <div className="sideNavMenu__body__content__nav-tabs">
                            <Link to="/" className="sideNavMenu__body__content__nav-tabs__active-tab" onClick={() => props.setOpenSideNavMenu(false)}>
                                <div className="sideNavMenu__body__content__nav-tabs__active-tab__icon"><HomeSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__active-tab__name">Home</div>
                            </Link>
                            {/* <div className="sideNavMenu__body__content__nav-tabs__tab" onClick={() => props.setOpenSideNavMenu(false)}>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><DashboardSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__name">Dashboard</div>
                            </div> */}
                            {/* <div className="sideNavMenu__body__content__nav-tabs__tab" onClick={() => props.setOpenSideNavMenu(false)}>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><SettingSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__name">Setting</div>
                            </div> */}
                            {/* <div className="sideNavMenu__body__content__nav-tabs__tab"
                                onClick={() => { document.getElementById("notificationBillNavBarButton").click(); document.getElementById("notificationBillNavBarButton").focus(); props.setOpenSideNavMenu(false) }}>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><NotificationBSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__name">Notifications</div>
                            </div> */}
                            {/* <div className="sideNavMenu__body__content__nav-tabs__tab" onClick={() => props.setOpenSideNavMenu(false)}>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><LangSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__name">Language (us)</div>
                            </div> */}
                            <div className="sideNavMenu__body__content__nav-tabs__tab" onClick={() => { startTour(); props.setOpenSideNavMenu(false) }}>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><MapSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__name">Start tour</div>
                            </div>
                            <div className="sideNavMenu__body__content__nav-tabs__tab" onClick={() => { window.location.reload(); }}>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><RefreshSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__name">Get updated data</div>
                            </div>
                            <div className="sideNavMenu__body__content__nav-tabs__tab" onClick={() => { props.setOpenSideNavMenu(false); switchTheme() }}>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><ContrastSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__name">{themeNotSelect}</div>
                            </div>
                            {/* <Link to="/emails" className="sideNavMenu__body__content__nav-tabs__tab" onClick={() => {props.setOpenSideNavMenu(false) }}>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><EnvelopeSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__name">Emails</div>
                            </Link> */}
                            {exec_link}
                            <div className="sideNavMenu__body__content__nav-tabs__tab" onClick={() => { props.setOpenSideNavMenu(false); logout() }}>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__icon"><LogoutSvg /></div>
                                <div className="sideNavMenu__body__content__nav-tabs__tab__name">Logout</div>
                            </div>
                        </div>
                    </div>

                    <div className="sideNavMenu__body__logo">
                        <AbuErdanLogoSvg />
                        <div className="sideNavMenu__body__logo__version">
                            7.0.1
                        </div>
                    </div>

                </div>

            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { runTour: state.tour, profile: state.userData, isCum: state.isComultive };
};

export default connect(mapStateToProps, {
    START_TOUR,
    LOGIN,
    LOGOUT,
    GET_OVERVIEW_DATA_SYNC,
    GET_OVERVIEW_DATA,
    CLEAR_GET_OVERVIEW_DATA,
    STOP_SCROLLING,
})(SideNavMenu);