import Types from "../../actions/Types";

const readersSummary = (state = null, action) => {
    switch (action.type) {
        case Types.GET_READERS_SUMMARY:
            return action.payload
        case Types.CLEAR_GET_READERS_SUMMARY:
            return null
        default:
            return state;
    }
};

export default readersSummary;