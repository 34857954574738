import millify from 'millify';

const milifyNumber = (num) => {
    return millify(num, {
        precision: 2,
        decimalSeparator: '.'
    });
}

const columnChartDataGenerator = (data, standard, labels, unit) => {

    if (standard) {
        let chartData = {
            series: [{
                name: 'Actual',
                data: data,
                color: "#3B465E"

            }, {
                name: 'Standard',
                data: standard,
                color: "#39db80"

            }],
            chart: {
                type: 'column',
                height: '350',
                borderRadius: '12',
                scrollablePlotArea: {
                    minWidth: data?.length * 40,
                    scrollPositionX: 1,
                    opacity: .7
                },
            },
            credits: {
                enabled: false,
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: labels,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: unit
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    `<td style="padding:0"><b>{point.y:.1f} ${unit}</b></td></tr>`,
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            }
        }
        return chartData
    } else {
        let chartData = {
            series: [{
                name: 'Data',
                data: data,
                color: "#3B465E"

            }],
            chart: {
                type: 'column',
                height: '350',
                borderRadius: '12',
                scrollablePlotArea: {
                    minWidth: data?.length * 40,
                    scrollPositionX: 1,
                    opacity: .7
                }
            },
            credits: {
                enabled: false,
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: labels,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: unit
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    `<td style="padding:0"><b>{point.y:.1f} ${unit}</b></td></tr>`,
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            }
        }
        return chartData
    }
}

export default columnChartDataGenerator;