import React from 'react'

function FullPageSpinner() {
    return (
        <div className="spinner-container">
            <div className="donut-spinner multi-spinner" />
        </div>
    )
}

export default FullPageSpinner;
