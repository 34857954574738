import React from 'react'
import { ReactComponent as AbuErdanLogoSvg } from "../../assets/svg-modules-icon/logo.svg";

function FullPageError({ day, isCum }) {
    return (
        <div className="fullpage-error">
            <div className="fullpage-error__ops">Oops!</div>
            <div className="fullpage-error__msg">
                <b>Something went wrong</b>
                {isCum ? (
                    <span>At {day} cumulative</span>
                ) : (
                    <span>At {day}</span>
                )}
            </div>
            <AbuErdanLogoSvg />
        </div>
    )
}

export default FullPageError
