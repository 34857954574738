import React from 'react';
import dayPpupImg from "./assets/reactor/reactor-tour-steps-daypopup.png";
import sideMenuImg from "./assets/reactor/reactor-tour-steps-sidemenu.png";
import bestFarmImg from "./assets/reactor/reactor-steps-farmcard-firstfarm.png";
import worstFarmImg from "./assets/reactor/reactor-steps-farmscard-worst-farm.png";

const tourSteps = [
    // {
    //     selector: '[id="tour-navbar-notification-icon"]',
    //     content: () => <div className="tour-msg-txt">You can check your notifications here</div>
    // },
    {
        selector: '[id="tour-navBar-filters-options-icon"]',
        content: () => (
            <div className="tour-msg-image-txt">
                <div className="tour-msg-image-txt__text" style={{ marginBottom: "1.5rem" }}>
                    Select your preference for dates and granularity of data.
                </div>
                <div className="tour-msg-image-txt__img">
                    <img src={dayPpupImg} />
                </div>
                <div className="tour-msg-image-txt__sub-text">
                    Filter by Today or Yesterday data, and display data by a single day or cumulative.
                </div>
            </div>
        )
    },
    {
        selector: '[id="tour-navBar-filtered-dayselected"]',
        content: () => <div className="tour-msg-txt">This is the day selected, <br />it is yesterday by default.</div>
    },
    {
        selector: '[id="tour-module-name-at-navbat"]',
        content: () => <div className="tour-msg-txt">This is the selected module.</div>
    },
    {
        selector: '[id="tour-navbar-menu-btn"]',
        content: () => (
            <div className="tour-msg-image-txt">
                <div className="tour-msg-image-txt__tall-img">
                    <img src={sideMenuImg} />
                </div>
                <div className="tour-msg-image-txt__sub-text">
                    This is the menu where you can go through multiple options.
                </div>
            </div>
        )
    },
    {
        selector: '[id="tour-home-page-details-cards-scrollx-top"]',
        content: () => <div className="tour-msg-txt">These are summary information. <br />you can swipe left and right to see more, <br />you can click for more detailed information</div>
    }
    ,
    {
        selector: '[id="tour-home-page-details-cards-edit"]',
        content: () => <div className="tour-msg-txt">Customize your summary information cards. <br />You can edit/delete the summary information cards.</div>
    }
    ,
    {
        selector: '[id="tour-homePageShowChartsInDaysOrWeeks"]',
        content: () => <div className="tour-msg-txt">You can switch between days and weeks to reflect on the data displayed on the chart.</div>
    }
    ,
    {
        selector: '[id="tour-sortting-dropdown-production-farm"]',
        content: () => <div className="tour-msg-txt">You can sort the farms by age, weight, or alphabetically.</div>
    }
    ,
    {
        selector: '[id="tour-homePageFarmsDataCard"]',
        content: () => (
            <div className="tour-farm-card">
                <div className="tour-farm-card__text">
                    This the farm data
                </div>
                <div className="tour-farm-card__sub-text">
                    Click on the farm for more detailed information
                </div>
                <div className="tour-farm-card__fields">
                    <div className="tour-farm-card__fields__field">
                        <div className="tour-farm-card__fields__field__img">
                            <img src={bestFarmImg} />
                        </div>
                        <div className="tour-farm-card__fields__field__text">Best farm</div>
                    </div>
                    <div className="tour-farm-card__fields__field">
                        <div className="tour-farm-card__fields__field__img">
                            <img src={worstFarmImg} />
                        </div>
                        <div className="tour-farm-card__fields__field__text">Worst farm</div>
                    </div>
                </div>
            </div>
        )
    }
    ,
    {
        selector: '[id="tour-farm-card-managername"]',
        content: () => <div className="tour-msg-txt">This is the farm manager name.</div>
    }
    ,
    {
        selector: '[id="tour-toggable-chart-breeders"]',
        content: () => <div className="tour-msg-txt">Some charts will have options to display the data differently.</div>
    }
    ,
    {
        selector: '[id="tour-homePageFarmsChart"]',
        content: () => <div className="tour-msg-txt">Some charts can be swiped left and right to show more data.</div>
    }
    ,
    {
        selector: '[id="tour-homepage-bot-navtabs"]',
        content: () => <div className="tour-msg-txt">You can switch between modules by clicking on module</div>
    }
];

export default tourSteps;