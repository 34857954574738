import React, { Fragment, useEffect, useState } from 'react'
import FarmCard from './FarmCard'
import BreedersFarmCard from './BreedersFarmCard';
import _ from "lodash";
import Dropdown from 'react-dropdown';


function ProductionFarmsDataField({ farms }) {

    //handle change sort Dropdown value, and select which type of sort will apply to filteredData reducer
    function handleChange(value) {
        if (value.value == "Sort by age") {
            filterByAge()
        } else if (value.value == "Sort by weight") {
            filterByWight()
        } else if (value.value == "Alpha a-z") {
            filterByAlpha()
        } else {
            filterByAge()
        }
    }

    const options = [
        'Sort by age', 'Alpha a-z'
    ];

    const [filteredData, setFilteredData] = useState()

    const filterByAge = () => {
        setFilteredData(
            _.sortBy(farms, [
                function (farm) {
                    return farm.age_in_days;
                },
            ]).reverse()
        );
    }

    const filterByWight = () => {
        setFilteredData(
            _.sortBy(farms, [
                function (farm) {
                    return farm.weight;
                },
            ]).reverse()
        );
    }

    const filterByAlpha = () => {
        setFilteredData(
            _.sortBy(farms, [
                function (farm) {
                    return farm.name;
                },
            ])
        );
    }

    useEffect(() => {
        if (farms) {
            setFilteredData(
                _.sortBy(farms, [
                    function (farm) {
                        return farm.age_in_days;
                    },
                ]).reverse()
            );
        }
    }, [farms])


    return (
        <Fragment>
            <div className="home-page__top__title">
                Production Farms
                
                <div className="home-page__top__title__dropdown" id="tour-sortting-dropdown-production-farm">
                    <Dropdown options={options} onChange={handleChange} value={options[0]} placeholder="Select an option" />
                </div>
            </div>
            <div className="home-page__top__cards">
                {filteredData?.length > 0 && filteredData.map((farm, index) => {
                    return <BreedersFarmCard data={farm} key={index} />
                })}
            </div>
        </Fragment>
    )
}

export default React.memo(ProductionFarmsDataField);