import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";

import NavBar from '../../layouts/navBar/NavBar';
import { Switch } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {
    SET_MODULE_SELECTED,
    SHOW_INFO_MSG_HOME,
    STOP_SCROLLING,
} from "../../redux/actions/appData/index";

import {
    GET_OVERVIEW_DATA,
    GET_OVERVIEW_DATA_SYNC,
    CLEAR_GET_OVERVIEW_DATA,
} from "../../redux/actions/overView/index";

import FarmsDataField from './FarmsDataField';
import RearingFarmsDataField from './RearingFarmsDataField';
import ProductionFarmsDataField from './ProductionFarmsDataField';
import HatcheryFarmsDataField from './HatcheryFarmsDataField';
import HomePageReadersSummary from "./HomePageReadersSummary";
import HomePageBotNavTabs from "./HomePageBotNavTabs";

import FullPageSpinner from '../../layouts/spinners/FullPageSpinner';
import FullPageNoData from '../../layouts/noData/FullPageNoData';
import NoInternet from '../../layouts/noInternet/NoInternet';
import InfoMsgHomeSumary from '../../layouts/infoMsg/InfoMsgHomeSumary';
import StartTourPopUp from '../../layouts/startTourPopUp/StartTourPopUp';
import FullPageError from '../../layouts/handleError/FullPageError';

import generateNotifications from "../../helpers/generateNotifications";
import TablesContainer from '../tables/TablesContainer';

import ErrorBoundary from "../../helpers/ErrorBoundaries";

import columnChartDataGenerator from "../../helpers/chartGenerators/columnChartDataGenerator";
import barChartDataGenerator from "../../helpers/chartGenerators/barChartDataGenerator";
import hatcheryOutpuChartColumnGenerator from "../../helpers/chartGenerators/hatcheryOutpuChartColumnGenerator";
import pieChartGenerator from "../../helpers/chartGenerators/pieChartGenerator";
import hatcheryColumnChartGenerator from "../../helpers/chartGenerators/hatcheryColumnChartGenerator";
import basicLineChartDataGenerator from "../../helpers/chartGenerators/basicLineChartDataGenerator";
import broilersCostBarChartDataGenerator from "../../helpers/chartGenerators/broilersCostBarChartDataGenerator";

const LayersFeedConsumptionTable = React.lazy(() => import("../tables/LayersFeedConsumptionTable"));
const LayersFeedConsumptionTablePerBird = React.lazy(() => import("../tables/LayersFeedConsumptionTablePerBird"));
const AgesTable = React.lazy(() => import("../tables/AgesTable"));
const BroilersCostTable = React.lazy(() => import("../tables/BroilersCostTable"));
const MaleFemaleChartTable = React.lazy(() => import("../tables/MaleFemaleChartTable"));

function HomePageRender({
    moduleDataSelected,
    modules,
    gstate,
    monthly,
    GET_OVERVIEW_DATA,
    GET_OVERVIEW_DATA_SYNC,
    CLEAR_GET_OVERVIEW_DATA,
    SHOW_INFO_MSG_HOME,
    SET_MODULE_SELECTED,
    STOP_SCROLLING,
}) {
    const isCum = localStorage.getItem('isCum')
    
    //line charts tooltip mode toggle
    const [toggleLineChartTooltip, setToggleLineChartTooltip] = useState([]);

    const toggleLineChartTooltipHandler = async (index, value, title) => {
        let toggleLineChartTooltipArr = [...toggleLineChartTooltip]
        toggleLineChartTooltipArr[index] = value
        await setToggleLineChartTooltip(toggleLineChartTooltipArr)
        const cachedToggleLineChartTooltip = JSON.parse(localStorage.getItem("toggleLineChartTooltip"))
        let newValue = cachedToggleLineChartTooltip
        if (gstate.moduleSelected && title && cachedToggleLineChartTooltip && cachedToggleLineChartTooltip[gstate.moduleSelected]) {
            if (cachedToggleLineChartTooltip[gstate.moduleSelected].length > 0) {
                const itemIndex = cachedToggleLineChartTooltip[gstate.moduleSelected].findIndex(chart => chart === title)
                if (itemIndex >= 0 && !value) {
                    newValue[gstate.moduleSelected].splice(itemIndex, 1)
                    localStorage.setItem("toggleLineChartTooltip", JSON.stringify(newValue))
                } else if (!itemIndex >= 0 && value) {
                    newValue[gstate.moduleSelected].push(title)
                    localStorage.setItem("toggleLineChartTooltip", JSON.stringify(newValue))
                }
            } else {
                newValue[gstate.moduleSelected].push(title)
                localStorage.setItem("toggleLineChartTooltip", JSON.stringify(newValue))
            }
        }
        let broilersLineChartArr = [...broilersLineChart]
        broilersLineChartArr[index].chart = basicLineChartDataGenerator(moduleDataSelected.broilers_charts[index], toggleLineChartTooltipArr, index)
        setBroilersLineChart(broilersLineChartArr)
    }

    //control of charts return in the module data
    const [charts, setCharts] = useState([])
    //hatchery output chart
    const [hatcheryChart, setHatcheryChart] = useState()
    //hatchery pie chart
    const [hatcheryPieChart, setHatcheryPieChart] = useState()
    //hatchery charts (3 bars)
    const [hatcheryColumnCharts, setHatcheryColumnCharts] = useState([])
    //broilers cost chart "bars"
    const [broilersCostChart, setBroilersCostChart] = useState()
    //broilers line chart
    const [broilersLineChart, setBroilersLineChart] = useState([])

    useEffect(() => {
        //control of charts return in the module data
        if (moduleDataSelected && moduleDataSelected.charts && moduleDataSelected.charts.length > 0) {
            let chartArr = []
            moduleDataSelected.charts.map((chart, index) => {
                if (chart) {
                    if (chart.has_toggle) {
                        chartArr.push({
                            toggle: true,
                            unit: chart.unit,
                            unitToggled: chart.unit_toggle,
                            title: chart.chart_title,
                            titleToggled: chart.chart_title_toggle,
                            chart: columnChartDataGenerator(chart.data, chart.standard, chart.labels, chart.unit),
                            chartToggled: columnChartDataGenerator(chart.data_toggle, chart.standard_toggle, chart.labels, chart.unit_toggle),
                        })
                    } else {
                        chartArr.push({
                            toggle: false,
                            unit: chart.unit,
                            title: chart.chart_title,
                            chart: columnChartDataGenerator(chart.data, chart.standard, chart.labels, chart.unit)
                        })
                    }
                }
            })
            setCharts(chartArr);
        } else {
            setCharts([])
        }

        //hatchery output chart
        if (moduleDataSelected && moduleDataSelected.output_chart) {
            let chartData = hatcheryOutpuChartColumnGenerator(moduleDataSelected.output_chart)
            setHatcheryChart(chartData)
        } else {
            setHatcheryChart(null)
        }

        //hatchery pie chart
        if (moduleDataSelected && moduleDataSelected.pie_chart) {
            let chartData = pieChartGenerator(JSON.stringify(moduleDataSelected.pie_chart.yAxis), moduleDataSelected.pie_chart.unit, moduleDataSelected.pie_chart.yAxis_title)
            setHatcheryPieChart(chartData)
        } else {
            setHatcheryPieChart(null)
        }

        //hatchery charts (3 bars)
        if (moduleDataSelected && moduleDataSelected.hatchery_charts?.length > 0) {
            let charts = []
            moduleDataSelected.hatchery_charts.map((chart, index) => {
                charts.push(hatcheryColumnChartGenerator(chart))
            })
            setHatcheryColumnCharts(charts)
        } else {
            setHatcheryColumnCharts(null)
        }

        //broilers cost chart "bars"
        if (moduleDataSelected && moduleDataSelected.cost_chart) {
            let chartData = broilersCostBarChartDataGenerator(moduleDataSelected.cost_chart)
            setBroilersCostChart(chartData)
        } else {
            setBroilersCostChart(null)
        }
    }, [moduleDataSelected])

    useEffect(() => {
        //broilers line chart
        if (moduleDataSelected && moduleDataSelected.broilers_charts?.length > 0) {
            setToggleLineChartTooltip([])
            let chartArr = []
            let toggleLineChartTooltipArr = []
            const cachedToggleLineChartTooltip = JSON.parse(localStorage.getItem("toggleLineChartTooltip"))
            moduleDataSelected.broilers_charts.map((chart, index) => {
                if (cachedToggleLineChartTooltip[gstate.moduleSelected]?.length > 0 && cachedToggleLineChartTooltip[gstate.moduleSelected].find(item => item === chart.title)) {
                    toggleLineChartTooltipArr.push(true);
                } else {
                    toggleLineChartTooltipArr.push(false);
                }
                chartArr.push({
                    unit: chart.unit,
                    title: chart.title,
                    chart: basicLineChartDataGenerator(chart, toggleLineChartTooltipArr, index),
                    table: chart.data
                })
            })
            setBroilersLineChart(chartArr);
            toggleLineChartTooltip.length === 0 && setToggleLineChartTooltip(toggleLineChartTooltipArr);
        } else {
            setBroilersLineChart([])
        }
    }, [moduleDataSelected, gstate.moduleSelected])



    //handle toggle chart if it has is toggle prop
    const toggleChart = (index) => {
        let newArr = [...charts]

        let title = charts[index].titleToggled
        let toggleTitle = charts[index].title
        let unit = JSON.stringify(charts[index].unitToggled)
        let toggleUnit = JSON.stringify(charts[index].unit)
        let chart = JSON.stringify(charts[index].chartToggled)
        let toggleChart = JSON.stringify(charts[index].chart)

        newArr[index].title = title
        newArr[index].titleToggled = toggleTitle
        newArr[index].unit = JSON.parse(unit)
        newArr[index].unitToggled = JSON.parse(toggleUnit)
        newArr[index].chart = JSON.parse(chart)
        newArr[index].chartToggled = JSON.parse(toggleChart)

        setCharts(newArr)
    }

    //age chart
    const [ageChart, setAgeChart] = useState()

    const [ageChartByWeeks, setageChartByWeeks] = useState(false)

    useEffect(() => {
        if (moduleDataSelected && moduleDataSelected.age_chart) {
            let chartData = barChartDataGenerator(moduleDataSelected.age_chart.labels, JSON.stringify(moduleDataSelected.age_chart.age), ageChartByWeeks)
            setAgeChart(chartData)
        } else {
            setAgeChart(null)
        }
    }, [moduleDataSelected, ageChartByWeeks])


    //view info message 
    useEffect(() => {
        if (gstate.overViewData) {
            setTimeout(() => {
                SHOW_INFO_MSG_HOME(false)
            }, 7000);
        }
    }, [gstate.overViewData])


    //tabing between modules loading handler
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(false)
    }, [gstate.moduleSelected])

    //view start tour popup
    const [isPopUpOpen, setIsPopUpOpen] = useState(false)

    useEffect(() => {
        if (gstate.startTourPopUp && gstate.overViewData) {
            setTimeout(() => {
                setIsPopUpOpen(true)
            }, 8000);
        }
    }, [gstate.startTourPopUp, gstate.overViewData])

    useEffect(() => {
        if (isPopUpOpen) {
            STOP_SCROLLING(true)
        } else {
            STOP_SCROLLING(false)
        }
    }, [isPopUpOpen])

    //***call get Sync data API every day***//
    var date = new Date();
    var dayIndex = date.getDay()

    setTimeout(() => {
        localStorage.setItem("dayIndex", dayIndex);
    }, 5000);

    var oldDate = localStorage.getItem('dayIndex')

    useEffect(() => {
        if (oldDate && (oldDate != dayIndex)) {
            if (isCum) {
                GET_OVERVIEW_DATA_SYNC(isCum, CLEAR_GET_OVERVIEW_DATA, GET_OVERVIEW_DATA)
            } else {
                GET_OVERVIEW_DATA_SYNC(gstate.isComultive, CLEAR_GET_OVERVIEW_DATA, GET_OVERVIEW_DATA)
            }
        }
        if (!localStorage.getItem("toggleLineChartTooltip")) {
            localStorage.setItem("toggleLineChartTooltip", JSON.stringify({ Breeders: [], Hatchery: [], Broilers: [], Layers: [], Slaughterhouse: [] }))
        }
    }, [])

    return (
        <Fragment>
            <NavBar />
            {/* Handle >> 1-Api is loading 2-Api return 500 3-Single module has no data 4-no internet connection*/}
            {gstate.overViewData && !isLoading ? (
                moduleDataSelected ? (
                    <div className="home-page">
                        <div className="home-page__top">
                            <HomePageReadersSummary data={moduleDataSelected?.data} moduleSelected={gstate.moduleSelected} monthly={monthly} />

                            <div className="general-container" style={{ marginTop: "1rem", position: "relative" }}>
                                <InfoMsgHomeSumary msg="Click on the card to show more info" show={gstate.showInfoMsgHome} close={SHOW_INFO_MSG_HOME} />

                                <HatcheryFarmsDataField monthly_farms={moduleDataSelected?.hatchery_monthly_activities_farms} farms={moduleDataSelected?.hatchery_activities_farms} farms_hatchery_data={moduleDataSelected?.hatchery_farms} isCum={gstate.isComultive} />

                                {ageChart && (
                                    <div className="home-page__top__charts remove-highchart-legend">
                                        <div className="home-page__top__charts__head">
                                            <div className="home-page__top__charts__head__title" style={{ margin: "0" }}>Ages</div>
                                            <div className="home-page__top__charts__head__weeksOrDays" id="tour-homePageShowChartsInDaysOrWeeks">
                                                <button className={ageChartByWeeks ? "btn home-page__top__charts__head__weeksOrDays__not-active" : "btn home-page__top__charts__head__weeksOrDays__active"} onClick={() => setageChartByWeeks(false)}>days</button>
                                                <button className={ageChartByWeeks ? "btn home-page__top__charts__head__weeksOrDays__active" : "btn home-page__top__charts__head__weeksOrDays__not-active"} onClick={() => setageChartByWeeks(true)}>weeks</button>
                                            </div>
                                        </div>
                                        <div className="home-page__top__charts__cont" id="tour-homePageFarmsAgesChartk">
                                            <HighchartsReact highcharts={Highcharts} options={ageChart} />
                                            <TablesContainer>
                                                <ErrorBoundary>
                                                    <AgesTable data={ageChart} moduleSelected={gstate.moduleSelected} />
                                                </ErrorBoundary>
                                            </TablesContainer>
                                        </div>
                                    </div>
                                )}

                                {hatcheryChart && (
                                    <div className="home-page__top__charts remove-highchart-legend">
                                        <div className="home-page__top__charts__head">
                                            <div className="home-page__top__charts__head__title" style={{ margin: "0" }}>{moduleDataSelected.output_chart?.chart_title}</div>
                                        </div>
                                        <div className="home-page__top__charts__cont" id="tour-homePagehatcheryChart">
                                            <HighchartsReact highcharts={Highcharts} options={hatcheryChart} />
                                        </div>
                                    </div>
                                )}

                                {hatcheryPieChart && (
                                    <div className="home-page__top__charts remove-highchart-legend remove-highchart-background-color ">
                                        <div className="home-page__top__charts__head">
                                            <div className="home-page__top__charts__head__title" style={{ margin: "0" }}>{moduleDataSelected.pie_chart?.yAxis_title}</div>
                                        </div>
                                        <div className="home-page__top__charts__cont" id="tour-homePagehatcheryPieChart">
                                            <HighchartsReact highcharts={Highcharts} options={hatcheryPieChart} />
                                        </div>
                                    </div>
                                )}

                                {moduleDataSelected?.farms?.length > 0 && (
                                    <FarmsDataField farms={moduleDataSelected?.farms} fromHome={true} />
                                )}

                                {moduleDataSelected?.production_farms?.length > 0 && (
                                    <ProductionFarmsDataField farms={moduleDataSelected?.production_farms} />
                                )}

                                {moduleDataSelected?.rearing_farms?.length > 0 && (
                                    <RearingFarmsDataField farms={moduleDataSelected?.rearing_farms} />
                                )}

                                {charts.length > 0 && (
                                    charts.map((chart, index) => {
                                        return (
                                            <div className="home-page__top__charts" key={index}>
                                                {chart.toggle ? (
                                                    <Fragment>
                                                        <div className="home-page__top__charts__head" id="tour-toggable-chart-breeders">
                                                            <div className="home-page__top__charts__head__switch">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`chartToggleCheckboxHome`}
                                                                        onChange={() => toggleChart(index)}
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                            </div>
                                                            <div className="home-page__top__charts__head__title">{chart.title}</div>
                                                        </div>
                                                        <div className="home-page__top__charts__cont" id="tour-homePageFarmsChart">
                                                            <HighchartsReact highcharts={Highcharts} options={chart.chart} />
                                                            <TablesContainer>
                                                                <ErrorBoundary>
                                                                    {(chart.title === "Feed Consumption per Bird" || chart.title === "Feed Consumption Per Bird Chart") && <LayersFeedConsumptionTablePerBird data={chart.chart} unit={chart.unit} title={chart.title} />}
                                                                    {chart.title === "Feed Consumption" && <LayersFeedConsumptionTable data={chart.chart} unit={chart.unit} />}
                                                                </ErrorBoundary>
                                                            </TablesContainer>
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <div className="home-page__top__charts__head">
                                                            <div className="home-page__top__charts__head__title">{chart.title}</div>
                                                        </div>
                                                        <div className="home-page__top__charts__cont">
                                                            <HighchartsReact highcharts={Highcharts} options={chart.chart} />
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </div>
                                        )
                                    })
                                )}

                                {hatcheryColumnCharts && hatcheryColumnCharts.length > 0 && moduleDataSelected?.hatchery_charts && (
                                    hatcheryColumnCharts.map((chart, index) => {
                                        return (
                                            <div className="home-page__top__charts remove-highchart-legend" key={index}>
                                                <div className="home-page__top__charts__head">
                                                    <div className="home-page__top__charts__head__title" style={{ margin: "0" }}>{moduleDataSelected?.hatchery_charts[index].chart_title}</div>
                                                </div>
                                                <div className="home-page__top__charts__cont" id="tour-homePagehatcheryPieChart">
                                                    <HighchartsReact highcharts={Highcharts} options={chart} />
                                                </div>
                                            </div>
                                        )
                                    })
                                )}

                                {broilersCostChart && (
                                    <div className="home-page__top__charts remove-highchart-legend">
                                        <div className="home-page__top__charts__head">
                                            <div className="home-page__top__charts__head__title" style={{ margin: "0" }}>{moduleDataSelected.cost_chart?.chart_title}</div>
                                        </div>
                                        <div className="home-page__top__charts__cont" id="tour-homePagehatcheryChart">
                                            <HighchartsReact highcharts={Highcharts} options={broilersCostChart} />
                                            <TablesContainer>
                                                <ErrorBoundary>
                                                    <BroilersCostTable data={broilersCostChart} />
                                                </ErrorBoundary>
                                            </TablesContainer>
                                        </div>
                                    </div>
                                )}

                                {broilersLineChart?.length > 0 && (
                                    <div className="farm-details__body__chart">
                                        {broilersLineChart.map((chart, index) => {
                                            return (
                                                <div className="home-page__top__charts remove-highchart-legend" key={index}>
                                                    <div className="home-page__top__charts__head" style={{ justifyContent: "space-between" }}>
                                                        <div className="home-page__top__charts__head__title" style={{ margin: "0" }}>{chart?.title}</div>
                                                        <div className='home-page__top__charts__head__tooltip'>
                                                            <div className='home-page__top__charts__head__tooltip__title'>Tooltip: </div>
                                                            <Switch
                                                                checkedChildren="Single field"
                                                                unCheckedChildren="All fields"
                                                                checked={toggleLineChartTooltip.length > 0 && toggleLineChartTooltip[index]}
                                                                onChange={value => toggleLineChartTooltipHandler(index, value, chart.title)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="home-page__top__charts__cont" id="tour-homePagehatcheryPieChart">
                                                        <HighchartsReact highcharts={Highcharts} options={chart.chart} />
                                                        <TablesContainer>
                                                            <ErrorBoundary>
                                                                <MaleFemaleChartTable data={chart.table} unit={chart.unit} />
                                                            </ErrorBoundary>
                                                        </TablesContainer>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}

                                <div className="home-page__top__more-details">
                                    {/* <Link to={`/module-details/${gstate.moduleSelected}`} className="btn btn__more-details">View more details &rarr;</Link> */}
                                </div>
                            </div>
                        </div>

                        <HomePageBotNavTabs modules={modules} moduleSelected={gstate.moduleSelected} SET_MODULE_SELECTED={SET_MODULE_SELECTED} setIsLoading={setIsLoading} />

                    </div>
                ) : (
                    <div className="home-page">
                        <FullPageNoData />
                        <HomePageBotNavTabs modules={modules} moduleSelected={gstate.moduleSelected} SET_MODULE_SELECTED={SET_MODULE_SELECTED} setIsLoading={setIsLoading} />
                    </div>
                )
            ) : (window.navigator.onLine ? (gstate.getOverviewDataError ? <FullPageError day={gstate.daySelected?.value} isCum={gstate.isComultive} /> : <FullPageSpinner />)
                : <NoInternet />)}

            <StartTourPopUp isPopUpOpen={isPopUpOpen} setIsPopUpOpen={setIsPopUpOpen} />
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    SET_MODULE_SELECTED,
    GET_OVERVIEW_DATA,
    GET_OVERVIEW_DATA_SYNC,
    CLEAR_GET_OVERVIEW_DATA,
    SHOW_INFO_MSG_HOME,
    STOP_SCROLLING,
})(React.memo(HomePageRender));