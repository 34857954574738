import React, { Fragment, useEffect, useState } from 'react'
import NavBar from '../../layouts/navBar/NavBar'
import { connect } from "react-redux";
import ReadersSummaryCard from './ReadersSummaryCard';
import _ from "lodash";

import {
    GET_READERS_SUMMARY,
    CLEAR_GET_READERS_SUMMARY,
} from "../../redux/actions/readersSummary/index";

import {
    SET_MODULE_SELECTED,
} from "../../redux/actions/appData/index";

import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import FullPageSpinner from '../../layouts/spinners/FullPageSpinner';
import NoInternet from '../../layouts/noInternet/NoInternet';
import FullPageError from '../../layouts/handleError/FullPageError';

function ReaderSummary(props) {
    const responsive = {
        all: {
            breakpoint: { max: 10000, min: 10 },
            items: 1,
        },

    };

    const isCum = localStorage.getItem('isCum')

    useEffect(() => {
        if (props.match.params.module) {
            if (isCum) {
                props.GET_READERS_SUMMARY(props.match.params.module, props.gstate.daySelected, JSON.parse(isCum), props.match.params.type)
            } else {
                props.GET_READERS_SUMMARY(props.match.params.module, props.gstate.daySelected, props.gstate.isComultive, props.match.params.type)
            }
        }
    }, [props.match.params.module, props.gstate.daySelected, props.gstate.isComultive])

    useEffect(() => {
        if(["Breeders", "Hatchery", "Broilers", "Layers"].includes(props.match.params.module)) {
            props.SET_MODULE_SELECTED(props.match.params.module)
        }
    }, [])

    useEffect(() => {
        return () => {
            //cleanup on leaving
            props.CLEAR_GET_READERS_SUMMARY()
        }
    }, [])

    //control the card index will show first
    const [indexNum, setIndexNum] = useState(0)

    useEffect(() => {
        if (props.match.params.index) {
            setIndexNum(props.match.params.index)
        }
    }, [props.match.params.index])

    //put the selected card index at first in the arr to show first in the carousel
    const [cards, setCards] = useState([])

    useEffect(() => {
        if (props.gstate.readersSummary && props.gstate.readersSummary.card_summary && props.gstate.readersSummary.card_summary.length > 0) {
            let arrSplitA = []
            let arrSplitB = []
            props.gstate.readersSummary.card_summary.map((card, index) => {
                if (index >= indexNum) {
                    arrSplitA.push(card)
                } else {
                    arrSplitB.push(card)
                }
            })
            let resultArr = [...arrSplitA, ...arrSplitB]
            setCards(resultArr);
        }
    }, [props.gstate.readersSummary, indexNum])

    //sort by age
    const filterByAge = (farms) => {
        let sortedData = _.sortBy(farms, [
            function (farm) {
                return farm.age_in_days;
            },
        ]).reverse()

        return sortedData
    }


    return (
        <Fragment>
            <NavBar atDetails={true} moduleName={["Breeders", "Hatchery", "Broilers", "Layers"].includes(props.match.params.module) ? props.match.params.module : false} />
            {props.gstate.readersSummary && cards?.length > 0 ? (
                <div className="readers-summary general-container">
                    <div style={{ margin: "2rem 0" }}>
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            showDots={true}
                            arrows={false}
                        >
                            {
                                cards.length > 0 && cards.map((card, index) => {
                                    return (
                                        <div className="readers-summary__card" key={index}>
                                            <div className="readers-summary__card__title">{card.name}</div>
                                            <div className="readers-summary__card__body">
                                                {card.farms?.length > 0 && filterByAge(card.farms).map((farm, i) => {
                                                    return <ReadersSummaryCard data={farm} key={i} />
                                                })}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            ) : (window.navigator.onLine ? (props.gstate.getDataError ? <FullPageError day={props.gstate.daySelected?.value} isCum={props.gstate.isComultive} /> : <FullPageSpinner /> )
            : <NoInternet />)}
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    GET_READERS_SUMMARY,
    CLEAR_GET_READERS_SUMMARY,
    SET_MODULE_SELECTED,
})(React.memo(ReaderSummary));