import Types from "../../actions/Types";

const getOverviewDataError = (state = false, action) => {
    switch (action.type) {
        case Types.IS_GET_DATA_ERROR:
            return action.payload
        default:
            return state;
    }
};

export default getOverviewDataError;