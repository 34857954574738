import Types from "../../actions/Types";

const INITIAL_STATE = {
    data: null,
    loading: true,
    error: null
}

const readersSummaryEditCards = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.GET_READERS_SUMMARY_EDIT_CARDS:
            return {
                ...state,
                data: action.payload
            }
        case Types.GET_READERS_SUMMARY_EDIT_CARDS_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case Types.GET_READERS_SUMMARY_EDIT_CARDS_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case Types.CLEAR_GET_READERS_SUMMARY_EDIT_CARDS:
            return INITIAL_STATE
        default:
            return state;
    }
};

export default readersSummaryEditCards;