import React from 'react'

import { ReactComponent as BroilersSvg } from "../../assets/svg-modules-icon/broilers_icon_tr.svg";
import { ReactComponent as GrandParentSvg } from "../../assets/svg-modules-icon/grandparents_icon_tr.svg";
import { ReactComponent as SlaughterSvg } from "../../assets/svg-modules-icon/slaughter_icon_tr.svg";
import { ReactComponent as BreedersSvg } from "../../assets/svg-modules-icon/breeders_icon_tr.svg";
import { ReactComponent as HatcherySvg } from "../../assets/svg-modules-icon/hatchery_icon_tr.svg";


function HomePageBotNavTabs({modules, moduleSelected, SET_MODULE_SELECTED, setIsLoading}) {

    //decide on module icon based on module selected name
    const renderModuleIcon = (moduleSelected) => {
        switch (moduleSelected) {
            case 'Breeders':
                return <BreedersSvg />;
            case 'Broilers':
                return <BroilersSvg />;
            case 'Hatchery':
                return <HatcherySvg />;
            case 'SlaughterHouse':
                return <SlaughterSvg />;
            case 'GrandParent':
                return <GrandParentSvg />;
            // case 'Breeders':
            //     return <BreedersSvg />;
            default:
                return <BreedersSvg />;
        }
    }

    const selectModule = (moduleChosed) => {
        setIsLoading(true);
        //setTimeout here to make SET_MODULE_SELECTED execute out of it's normal flow, after JS finish its job while filling the data of the selected module
        setTimeout(() => {
            SET_MODULE_SELECTED(moduleChosed);
        }, 0);
    }

    return (
        <div className="home-page__bot" id="tour-homepage-bot-navtabs">
            {modules && modules.map((moduleChosed, index) => {
                return (
                    moduleSelected == moduleChosed ? (
                        <div className="home-page__bot__item-active" key={index}>
                            <div className="home-page__bot__item-active__icon">
                                {renderModuleIcon(moduleChosed)}
                            </div>
                            <div className="home-page__bot__item-active__name">{moduleChosed}</div>
                        </div>
                    ) : (
                        <div className="home-page__bot__item" key={index} onClick={()=>selectModule(moduleChosed)}>
                            <div className="home-page__bot__item__icon">
                                {renderModuleIcon(moduleChosed)}
                            </div>
                            <div className="home-page__bot__item__name">{moduleChosed}</div>
                        </div>
                    )
                )
            })}
        </div>
    )
}

export default React.memo(HomePageBotNavTabs);
