import Types from "../Types";

export const START_TOUR = value => ({
    type: Types.START_TOUR,
    payload: value
})

export const CLEAR_START_TOUR = () => ({
    type: Types.CLEAR_START_TOUR,
    payload: false
})