import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import decimalAdjust from '../../helpers/decimalAdjust';
import { ReactComponent as HomeSvg } from "../../assets/home.svg";
import { ReactComponent as FirstSvg } from "../../assets/trophy.svg";
import { ReactComponent as UserSvg } from "../../assets/userFill.svg";

function FarmCard({ data, gstate, fromHome }) {
    //set age color based on age in days
    const decideAgeColor = (ageInDays) => {

        if (ageInDays < 20) {
            return "#ffa500"
        } else if (ageInDays < 35) {
            return "#248ecc"
        } else if (ageInDays >= 35) {
            return "#dd3b3b"
        }
    }

    const round10 = (value, exp) => decimalAdjust('round', value, exp);


    return (
        <Link to={`/farm-details/${gstate.moduleSelected}/${gstate.daySelected.value}/${data.id}`} className="farm-card" id="tour-homePageFarmCard" id="tour-homePageFarmsDataCard">
            <div className="farm-card__start">
                <div className="farm-card__start__head">
                    <div className="farm-card__start__head__name">
                        {data.name} <span>{data.placement_type}</span>
                        {data.best_farm == true && <div className="farm-card__start__head__name__icon"><FirstSvg /></div>}
                        {data.worst_farm == true && <div className="farm-card__start__head__name__icon-bad">Worst</div>}
                        {data.is_selling  == true && <div className="farm-card__start__head__name__selling">Selling</div>}
                    </div>
                </div>
                <div className="farm-card__start__body">
                    <div className="farm-card__start__body__item">
                        <div className="farm-card__start__body__item__value" style={{ backgroundColor: `${data?.age_in_days ? decideAgeColor(data.age_in_days) : "#dd3b3b"}`, color: "white" }}>
                            {fromHome ? `${data.age_in_days} days` : data.age}
                        </div>
                    </div>
                    <div className="farm-card__start__body__v-line" />
                    <div className="farm-card__start__body__item remove-on-900">
                        <div className="farm-card__start__body__item__value">
                            <HomeSvg />
                            <span>{data.house_number}</span>
                        </div>
                    </div>
                </div>
                {data.is_selling  == true && <div className="farm-card__start__selling">Selling</div>}
            </div>
            <div className="farm-card__end">
                <div className="farm-card__end__data">
                    <div className="farm-card__end__data__item">
                        <div className="farm-card__end__data__item__data">
                            {round10(data.weight, -3) >= round10(data.st_weight, -3) ? (
                                <b style={{ color: "#39db80" }}>{round10(data.weight, -3)}kg</b>
                            ) : <b style={{ color: "red" }}>{round10(data.weight, -3)}kg</b>
                            }
                            <span>Weight @{data.at_age}d</span>
                        </div>
                        <div className="farm-card__end__data__item__std">
                            <b>{round10(data.st_weight, -3)}kg</b>
                            <span>std</span>
                        </div>
                    </div>
                    <div className="farm-card__start__body__v-line" />
                    <div className="farm-card__end__data__item">
                        <div className="farm-card__end__data__item__data">
                            {data.loss_percent <= data.st_loss_percent ? (
                                <b style={{ color: "#39db80" }}>{round10(data.loss_percent, -3)}%</b>
                            ) : <b style={{ color: "red" }}>{round10(data.loss_percent, -3)}%</b>
                            }
                            <span>Loss</span>
                        </div>
                        <div className="farm-card__end__data__item__std">
                            <b>{round10(data.st_loss_percent, -3)}%</b>
                            <span>std</span>
                        </div>
                    </div>
                </div>
                {data.house_manager?.length > 0 && (
                    <div className="farm-card__end__foot">
                        <div className="farm-card__end__foot__name"><UserSvg />{data.house_manager.length == 1 ? data.house_manager[0] : `${data.house_manager[0]} ...`}</div>
                    </div>
                )}
            </div>
        </Link>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {

})(React.memo(FarmCard));