import React from 'react'

import NotificationDropDownCard from './NotificationDropDownCard';

import { connect } from "react-redux";


function NotificationDropDown(props) {

    return (
        <div className="notific-dropdown">
            <div className="notific-dropdown__head">
                    Notifications
            </div>
            <div className="notific-dropdown__body">
                {props.gstate.notificationData?.length > 0 && props.gstate.notificationData.map((notifc, index) => {
                    return <NotificationDropDownCard notifc={notifc} key={index} />
                })}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    
})(NotificationDropDown);