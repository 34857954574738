import React from 'react'
import ReactImageFallback from "react-image-fallback";
import { Link } from 'react-router-dom';

import useImg from "../../assets/user.png"

import { ReactComponent as FirstSvg } from "../../assets/trophy.svg";
import { ReactComponent as LastSvg } from "../../assets/exclamation-mark.svg";

function NotificationDropDownCard({ notifc }) {

    return (
        <Link to={notifc.link} className="notifications-dropdown-card">
            <div className="notifications-dropdown-card__top">
                <div className="notifications-dropdown-card__top__left">
                    {/* <ReactImageFallback
                        src=""
                        fallbackImage={useImg}
                        initialImage={useImg}
                        alt="user image"
                    /> */}
                    {notifc.bestFarm ? <FirstSvg /> : <LastSvg />}
                </div>
                <div className="notifications-dropdown-card__top__right">
                    <div className="notifications-dropdown-card__top__right__title">
                        {notifc?.farmName}
                    </div>
                    <div className="notifications-dropdown-card__top__right__sub-title">
                        {notifc?.farmManager?.length > 0 && notifc?.farmManager[0]}
                    </div>
                </div>
            </div>
            <div className="notifications-dropdown-card__mid">
                {notifc.bestFarm ? `This is best farm in ${notifc.field ? notifc.field : notifc.moduleName}` : `This is worst farm in ${notifc.field ? notifc.field : notifc.moduleName}`}
            </div>
            {/* <div className="notifications-dropdown-card__bot">
                26/01/2020
            </div> */}
        </Link>
    )
}

export default NotificationDropDownCard
