import React, { Suspense, useState } from 'react'
import { Collapse } from 'antd';
import Loading from './Loading';

const { Panel } = Collapse;

function TablesContainer({ children }) {
    const [showTable, setShowTable] = useState(false)
    const [tableIsOpen, settableIsOpen] = useState(false)
    // console.log(children, "children");

    function callback(key) {
        settableIsOpen(!tableIsOpen)
        if (key[0] && key[0] === '1') {
            setTimeout(() => {
                setShowTable(true)
            }, 500);
        } else {
            setTimeout(() => {
                setShowTable(false)
            }, 500);
        }
    }

    return (
        <div>
            <Collapse onChange={callback}>
                <Panel header={!tableIsOpen ? "Open Table View" : "Close Table View"} key="1">
                    <Suspense fallback={<Loading />}>
                        {showTable ? children : <Loading />}
                    </Suspense>
                </Panel>
            </Collapse>
        </div>
    )
}

export default TablesContainer
