import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

const ProtectedRoute = ({ component: Component, isLoggedIn, ...rest }) => {

    const [isAuth, setIsAuth] = useState(true)

    useEffect(() => {
        const token = localStorage.getItem('UT')

        if (isLoggedIn) {
            setIsAuth(true)
        } else {
            setIsAuth(false)
        }
    }, [isLoggedIn])

    return (
        <Route {...rest} render={
            props => {
                if (isAuth) {
                    return <Component {...rest} {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: '/login',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        } />
    )
}

const mapStateToProps = (state) => {
    return { isLoggedIn: state.login };
};

export default connect(mapStateToProps)(ProtectedRoute);