import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import HouseCard from './HouseCard'
import _ from "lodash";
import { ReactComponent as SortSvg } from "../../assets/descendant.svg";
import Dropdown from 'react-dropdown';

function BreedersHousesDataField({ breeds, houses, moduleSelected }) {


    return (
        breeds?.length > 0 && breeds.map((breed, index) => {
            return <BreedersHousesDataFieldSingleBreed breed={breed} houses={houses} moduleSelected={moduleSelected} key={index} />
        })
    )
}

export default BreedersHousesDataField



function BreedersHousesDataFieldSingleBreed({ breed, houses, moduleSelected, index }) {

    function handleChange(value) {
        if (value.value == "Sort by age") {
            filterByAge()
        } else if (value.value == "Sort by weight") {
            filterByWight()
        } else if (value.value == "Alpha a-z") {
            filterByAlpha()
        } else {
            filterByAge()
        }
    }

    const options = [
        'Sort by age', 'Sort by weight', 'Alpha a-z'
    ];

    const [filteredData, setFilteredData] = useState()

    const filterByAge = () => {
        if (houses[breed]) {
            setFilteredData(
                _.sortBy(houses[breed], [
                    function (house) {
                        return house.age_in_days;
                    },
                ]).reverse()
            );
        }
    }

    const filterByWight = () => {
        if (houses[breed]) {
            setFilteredData(
                _.sortBy(houses[breed], [
                    function (house) {
                        return house.weight;
                    },
                ]).reverse()
            );
        }
    }

    const filterByAlpha = () => {
        if (houses[breed]) {
            setFilteredData(
                _.sortBy(houses[breed], [
                    function (house) {
                        return house.name;
                    },
                ])
            );
        }
    }

    useEffect(() => {
        if (houses[breed]) {
            setFilteredData(
                _.sortBy(houses[breed], [
                    function (house) {
                        return house.age_in_days;
                    },
                ]).reverse()
            );
        }
    }, [breed])

    return (
        <Fragment>
            <div className="home-page__top__title">
                {breed}

                <div className="home-page__top__title__dropdown">
                    <Dropdown options={options} onChange={handleChange} value={options[0]} placeholder="Select an option" />
                </div>
            </div>
            <div className="farm-details__body__cards">
                {filteredData?.length > 0 && filteredData.map((house, index) => {
                    return <HouseCard data={house} key={index} moduleSelected={moduleSelected} />
                })}
            </div>
        </Fragment>
    )
}


