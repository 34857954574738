import React, { useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import {
    STOP_SCROLLING,
} from "../../redux/actions/appData/index"

import HomePageReadersSummaryEditPopup from '../homePage/HomePageReadersSummaryEditPopup';
import FarmDetailsReadersSummaryCard from './FarmDetailsReadersSummaryCard';

import { ReactComponent as UpArSvg } from "../../assets/up-arrow.svg";
import { ReactComponent as DownArSvg } from "../../assets/down-arrow.svg";
import { ReactComponent as PencilSvg } from "../../assets/pencil.svg";
import { ReactComponent as DeleteSvg } from "../../assets/delete.svg";


function FarmDetailsReadersSummary({ data, moduleSelected }) {

    const [isPopUpOpen, setIsPopUpOpen] = useState(false)

    useEffect(() => {
        if (isPopUpOpen) {
            STOP_SCROLLING(true)
        } else {
            STOP_SCROLLING(false)
        }
    }, [isPopUpOpen])

    const [openDeleteButtons, setOpenDeleteButtons] = useState(false)

    return (
        data?.length > 0 && (
            <Fragment>
                <div className="home-page__top__details" id="tour-home-page-details-cards-scrollx-top" style={{ marginTop: "5rem" }}>
                    {data.map((field, index) => {
                        return <FarmDetailsReadersSummaryCard field={field} index={index} openDeleteButtons={openDeleteButtons} key={index} />
                    })}
                    {/* <div className="home-page__top__details__options">
                        <div className="home-page__top__details__options__option" onClick={() => setIsPopUpOpen(true)}><PencilSvg /></div>
                        <div className="home-page__top__details__options__option" onClick={() => setOpenDeleteButtons(!openDeleteButtons)}><DeleteSvg /></div>
                    </div> */}
                </div>
                <HomePageReadersSummaryEditPopup isPopUpOpen={isPopUpOpen} setIsPopUpOpen={setIsPopUpOpen} />
            </Fragment>

        )
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    STOP_SCROLLING,
})(FarmDetailsReadersSummary);