import Highcharts from 'highcharts';
import millify from 'millify';

const milifyNumber = (num) => {
    return millify(num, {
        precision: 2,
        decimalSeparator: '.'
    });
}

const pieChartGenerator = (y, unit, title) => {

    let yAxis = JSON.parse(y)

    let series = []

    yAxis.map((item, index) => {
        series.push({
            name: item[0],
            y: item[1]
        })
    })

    var pieColors = (function () {
        var colors = [],
            base = Highcharts.getOptions().colors[0],
            i;

        for (i = 0; i < 10; i += 1) {
            // Start out with a darkened base color (negative brighten), and end
            // up with a much brighter color
            colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
        }
        return colors;
    }());

    let chartData = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            borderRadius: '12',
        },
        title: {
            text: ''
        },
        tooltip: {
            headerFormat: '<b style="font-size:12px;margin:10px 0">{% trans "Day" %} {point.key}</b><table>',
            pointFormatter: function () {
                return '<tr><td style="color:{series.color};padding:0">' + this.series.name + ': </td>' +
                    '<td style="padding:0"><b>' + (this.y && milifyNumber(this.y)) + " egg" + '</b></td></tr>'
            },
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
        },
        accessibility: {
            point: {
                valueSuffix: unit
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                colors: pieColors,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                    distance: -50,
                    filter: {
                        property: 'percentage',
                        operator: '>',
                        value: 4
                    },
                    showInLegend: true
                }
            }
        },
        series: [{
            name: 'Eggs Grade',
            data: series
        }],
        credits: {
            enabled: false,
        },
    }
    return chartData
}

export default pieChartGenerator;