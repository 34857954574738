import React, { Fragment, useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router';
import ProtectedRoute from './ProtectedRoute';
import Tour from "reactour";
import { connect } from "react-redux";
import axios from 'axios';

import 'react-dropdown/style.css';

import {
  START_TOUR,
} from "./redux/actions/tour/index";

import {
  GET_USER_DATA,
  LOGIN,
} from "./redux/actions/registration/index";

import {
  SET_DAY_SELECTED,
  SET_IS_COMULTIVE,
  STOP_SCROLLING,
  CLEAR_STOP_SCROLLING,
  SET_NOTIFICATIONS,
  SET_MODULE_SELECTED,
} from "./redux/actions/appData/index"

import {
  IS_GET_OVERVIEW_DATA_ERROR,
} from "./redux/actions/overView/index";

import HomePage from './components/homePage/HomePage';
import LoginPage from './components/loginPage/LoginPage';
import ReaderSummary from './components/readersSummary/ReaderSummary';
import FarmDetails from './components/farmDetails/FarmDetails';
import Emails from './components/emails/Emails';

import tourSteps from "./tourSteps"


function App(props) {

  // Notification.requestPermission(function (status) {
  //   console.log('Notification permission status:', status);
  // });

  // console.log(navigator.serviceWorker , "sw navigator");

  // function displayNotification() {
  //   if (Notification.permission == 'granted' && navigator.serviceWorker.controller) {
  //     navigator.serviceWorker.getRegistration().then(function(reg) {
  //       if(reg.showNotification) {
  //         console.log(reg.showNotification, "reg.showNotification");
  //         reg.showNotification('Hello world!');
  //       }
  //     });
  //   }
  // }

  // useEffect(() => {
  //   displayNotification()
  // }, [])

  //listen when routes change and check if there is new version
  //released and waiting to get activated
  const history = useHistory();

  useEffect(() => {
    if(navigator?.serviceWorker?.getRegistrations) {
      history.listen((location, action) => {
        // check for sw updates on page change
          navigator.serviceWorker
            .getRegistrations()
            .then((regs) => regs.forEach((reg) => reg.update()));
      });
    }
  }, []);


  //check for user token ** and check login status with true if we find user token saved
  const token = localStorage.getItem('UT')

  useEffect(() => {
    if (props.isLoggedIn && token) {
      props.GET_USER_DATA()
    }
  }, [props.isLoggedIn])

  useEffect(() => {
    if (token) {
      props.LOGIN(true)
    } else {
      props.LOGIN(false)
    }
  }, [])

  //check for is cum ? + check for the day filter selected saved in local
  const isCum = localStorage.getItem('isCum')
  const daySelected = localStorage.getItem('daySelected')

  useEffect(() => {
    if (isCum) {
      props.SET_IS_COMULTIVE(JSON.parse(isCum));
    }

    if (daySelected) {
      props.SET_DAY_SELECTED({ name: daySelected, value: daySelected })
    }
  }, [])

  //notifications **check if there is already saved notifications so get it **we need this when user refresh other page than home page
  const notifications = localStorage.getItem('notifications')

  useEffect(() => {
    if (notifications) {
      props.SET_NOTIFICATIONS(JSON.parse(notifications))
    }
  }, [])

  //stop scrolling in body ** we use it when the tour is started or when some popup opened and some other cases
  useEffect(() => {
    if (props.isScorolling) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [props.isScorolling])

  //control stop scorling when tour start
  useEffect(() => {
    if (props.tourIsRuning) {
      props.STOP_SCROLLING(true)
      props.SET_MODULE_SELECTED("Breeders")

    } else {
      props.CLEAR_STOP_SCROLLING()
    }
  }, [props.tourIsRuning])

  //tour guide
  const tourCurrentStep = (value) => {
    //do actions based on current tiur step
  }

  const [tourStep, setTourStep] = useState()

  //call AJAX to log @server when route changes or module selected changes
  const location = useLocation();
  const userUrl = localStorage.getItem('userUrl')

  useEffect(() => {
    axios.get(`https://exec.abuerdan.com/ping.html/?server=${userUrl}&email=${props.profile?.email}&route=${location.pathname}&module=${props.moduleSelected}`)
      .then(res => {
      }).catch((error) => {
      });
  }, [location, props.moduleSelected])

  //remove Error Message at home page if overview data reducer has data
  useEffect(() => {
    if (props.overViewData) {
      props.IS_GET_OVERVIEW_DATA_ERROR(false)
    }
  }, [props.getOverviewDataError, props.overViewData])


  return (
    <Fragment>
      <Switch>
        <ProtectedRoute exact path='/' component={HomePage} />
        <ProtectedRoute path='/farm-details/:module/:day/:id' component={FarmDetails} />
        <ProtectedRoute path='/readers-summary/:module/:index/:type' component={ReaderSummary} />
        <ProtectedRoute path='/emails' component={Emails} />
        <Route exact path="/login">
          {props.isLoggedIn && token ? <Redirect to="/" /> : <LoginPage />}
        </Route>
      </Switch>

      <Tour
        onRequestClose={() => props.START_TOUR(false)}
        steps={tourSteps}
        isOpen={props.tourIsRuning}
        rounded={4}
        accentColor="#3B465E"
        onBeforeClose={() => { props.CLEAR_STOP_SCROLLING(); setTourStep(0) }}
        getCurrentStep={tourCurrentStep}
        goToStep={tourStep}
      />
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login,
    isScorolling: state.stopScrolling,
    tourIsRuning: state.tour,
    profile: state.userData,
    moduleSelected: state.moduleSelected,
    overViewData: state.overViewData,
    getOverviewDataError: state.getOverviewDataError,
  };
};

export default connect(mapStateToProps, {
  START_TOUR,
  GET_USER_DATA,
  LOGIN,
  SET_DAY_SELECTED,
  SET_IS_COMULTIVE,
  STOP_SCROLLING,
  CLEAR_STOP_SCROLLING,
  SET_NOTIFICATIONS,
  SET_MODULE_SELECTED,
  IS_GET_OVERVIEW_DATA_ERROR,
})(App);