import Types from "../Types";

export const SET_DAY_SELECTED = day => ({
    type: Types.SET_DAY_SELECTED,
    payload: day
})

export const CLEAR_SET_DAY_SELECTED = () => ({
    type: Types.CLEAR_SET_DAY_SELECTED,
    payload: null
})

export const SET_MODULE_DATA_SELECTED = data => ({
    type: Types.SET_MODULE_DATA_SELECTED,
    payload: data
})

export const CLEAR_SET_MODULE_DATA_SELECTED = () => ({
    type: Types.CLEAR_SET_MODULE_DATA_SELECTED,
    payload: null
})

export const SET_MODULE_SELECTED = moduleSelected => ({
    type: Types.SET_MODULE_SELECTED,
    payload: moduleSelected
})

export const CLEAR_SET_MODULE_SELECTED = () => ({
    type: Types.CLEAR_SET_MODULE_SELECTED,
    payload: null
})

export const SET_IS_COMULTIVE = value => ({
    type: Types.SET_IS_COMULTIVE,
    payload: value
})

export const SHOW_INFO_MSG_HOME = value => ({
    type: Types.SHOW_INFO_MSG_HOME,
    payload: value
})

export const STOP_SCROLLING = value => ({
    type: Types.STOP_SCROLLING,
    payload: value
})

export const CLEAR_STOP_SCROLLING = () => ({
    type: Types.CLEAR_STOP_SCROLLING,
})

export const START_TOUR_POPUP = value => ({
    type: Types.START_TOUR_POPUP,
    payload: value
})

export const CLEAR_START_TOUR_POPUP = () => ({
    type: Types.CLEAR_START_TOUR_POPUP,
})

export const SET_NOTIFICATIONS = value => ({
    type: Types.SET_NOTIFICATIONS,
    payload: value
})

export const CLEAR_SET_NOTIFICATIONS = () => ({
    type: Types.CLEAR_SET_NOTIFICATIONS,
})

export const IS_GET_DATA_ERROR = value => ({
    type: Types.IS_GET_DATA_ERROR,
    payload: value
})