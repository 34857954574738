import decimalAdjust from "../decimalAdjust";

const toWeeks = (age) => {
    let ageDays = [...age];
    let ageWeeks = [];

    const round10 = (value, exp) => decimalAdjust('round', value, exp);

    ageDays.map((age, index) => {
        ageWeeks.push(round10(age[1] / 7, -1))
    })

    return ageWeeks;
}

const barChartDataGenerator = (labels, age, ageChartByWeeks) => {

    let chartData = {
        chart: {
            borderRadius: '12',
            type: 'bar'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: labels,
            title: {
                text: null
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: !ageChartByWeeks ? 'Ages (days)' : 'Ages (weeks)',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {
            valueSuffix: ' day',
            headerFormat: '',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                `<td style="padding:0"><b>{point.name: 1f}</b></td></tr>`,
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                },
                color: '#3B465E'
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Age',
            data: !ageChartByWeeks ? JSON.parse(age) : toWeeks(JSON.parse(age))
        }],
        credits: {
            enabled: false,
        },
    }

    return chartData
}

export default barChartDataGenerator;