import React, { Fragment, useEffect, useState } from 'react'
import { connect } from "react-redux";
import { ReactComponent as AddSvg } from "../../assets/addIcon.svg";
import { ReactComponent as RemoveSvg } from "../../assets/minusIcon.svg";
import { ReactComponent as CheckedSvg } from "../../assets/checked.svg";
import { ReactComponent as CanceldSvg } from "../../assets/cancel.svg";
import { ReactComponent as CheckBoxDoneSvg } from "../../assets/checkboxDone.svg";

function HomePageReadersSummaryEditPopup(props) {


    return (
        <Fragment>
            <div className="overlay-popup" onClick={() => props.setIsPopUpOpen(false)} style={!props.isPopUpOpen ? { display: "none" } : {}} />
            <div className="readers-summary-edit-popup" style={!props.isPopUpOpen ? { display: "none" } : {}} id="tour-day-filter-popup">
                <div className="readers-summary-edit-popup__title">Edit readers summary</div>
                <div className="readers-summary-edit-popup__items">
                    <ReadersSummaryEditPopupCard />
                    <ReadersSummaryEditPopupCard />
                    <ReadersSummaryEditPopupCard />
                    <ReadersSummaryEditPopupCard />
                    <ReadersSummaryEditPopupCard />
                    <ReadersSummaryEditPopupCard />
                    <ReadersSummaryEditPopupCard />
                    <ReadersSummaryEditPopupCard />
                    <ReadersSummaryEditPopupCard />
                </div>

                <div className="readers-summary-edit-popup__btns">
                    <div className="readers-summary-edit-popup__btns__btn-reset" onClick={() => { props.setIsPopUpOpen(false) }}>
                        <CanceldSvg />
                        <span>Cancel</span>
                    </div>
                    <div className="readers-summary-edit-popup__btns__btn-apply">
                        <CheckedSvg />
                        <span>Apply</span>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {

})(React.memo(HomePageReadersSummaryEditPopup));



function ReadersSummaryEditPopupCard() {
    const [summaryIsActive, setSummaryIsActive] = useState(true)

    return (
        summaryIsActive ? (
            <div className="readers-summary-edit-popup__items__item" onClick={()=>setSummaryIsActive(!summaryIsActive)}>
                <div className="readers-summary-edit-popup__items__item__checkBox">
                    <CheckBoxDoneSvg />
                </div>
                <span>Placement Females</span>
            </div>
        ) : (
            <div className="readers-summary-edit-popup__items__item-inactive" onClick={()=>setSummaryIsActive(!summaryIsActive)}>
                <div className="readers-summary-edit-popup__items__item-inactive__checkBox" />
                <span>Placement Females</span>
            </div>
        )
    )
}

