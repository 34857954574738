import React, { useState } from 'react'
import { Fragment } from 'react'

import NavBar from '../../layouts/navBar/NavBar'

import { ReactComponent as PlusSvg } from "../../assets/plus.svg";
import { ReactComponent as SendSvg } from "../../assets/send.svg";

import HistoryEmailCard from './HistoryEmailCard';
import MessageCard from './MessageCard';
import NewMsgTab from './NewMsgTab';

function Emails() {

    const [openNewMsgTab, setOpenNewMsgTab] = useState(false)

    const [viwMobileChat, setViwMobileChat] = useState(false)


    return (
        <Fragment>
            <NavBar atDetails={true} moduleName="Emails" viwMobileChat={viwMobileChat} setViwMobileChat={setViwMobileChat} />
            <div className="emails general-container">
                {/* <div className="emails__title">Emails</div> */}
                <div className="emails__body remove-on-900">
                    <div className="emails__body__left">
                        <div className="emails__body__left__new-msg">
                            <div className="emails__body__left__new-msg__btn" onClick={() => setOpenNewMsgTab(!openNewMsgTab)}>
                                New Message
                                {openNewMsgTab ? <div className="emails__body__left__new-msg__btn__svg-rotate"><PlusSvg /></div> : <div className="emails__body__left__new-msg__btn__svg"><PlusSvg /></div>}
                            </div>
                            {openNewMsgTab && <NewMsgTab setOpenNewMsgTab={setOpenNewMsgTab} />}
                        </div>
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                        <HistoryEmailCard />
                    </div>
                    <div className="emails__body__right">
                        <div className="emails__body__right__top">
                            <div className="emails__body__right__top__name">Mina Roger</div>
                        </div>
                        <div className="emails__body__right__mid">
                            <MessageCard send />
                            <MessageCard />
                            <MessageCard send />
                            <MessageCard />
                            <MessageCard send />
                            <MessageCard />
                            <MessageCard send />
                            <MessageCard />
                            <MessageCard send />
                        </div>
                        <div className="emails__body__right__bot">
                            <div className="emails__body__right__bot__input">
                                <input type="text" placeholder="Type your message" />
                                <div className="emails__body__right__bot__input__send"><SendSvg /></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="emails__body remove-greater-900">
                    {!viwMobileChat ? (
                        <div className="emails__body__left fade-in-from-left">
                            <div className="emails__body__left__new-msg">
                                <div className="emails__body__left__new-msg__btn" onClick={() => setOpenNewMsgTab(!openNewMsgTab)}>
                                    New Message
                                {openNewMsgTab ? <div className="emails__body__left__new-msg__btn__svg-rotate"><PlusSvg /></div> : <div className="emails__body__left__new-msg__btn__svg"><PlusSvg /></div>}
                                </div>
                                {openNewMsgTab && <NewMsgTab setOpenNewMsgTab={setOpenNewMsgTab} />}
                            </div>
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                            <HistoryEmailCard setViwMobileChat={setViwMobileChat} />
                        </div>
                    ) : (
                        <div className="emails__body__right fade-in-from-right">
                            <div className="emails__body__right__top">
                                <div className="emails__body__right__top__name">Mina Roger</div>
                            </div>
                            <div className="emails__body__right__mid">
                                <MessageCard send />
                                <MessageCard />
                                <MessageCard send />
                                <MessageCard />
                                <MessageCard send />
                                <MessageCard />
                                <MessageCard send />
                                <MessageCard />
                                <MessageCard send />
                            </div>
                            <div className="emails__body__right__bot">
                                <div className="emails__body__right__bot__input">
                                    <input type="text" placeholder="Type your message" />
                                    <div className="emails__body__right__bot__input__send"><SendSvg /></div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default Emails
