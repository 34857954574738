// import React, { useEffect, useState } from 'react';
// import * as serviceWorker from './serviceWorkerRegistration';
// import Modal from 'antd/lib/modal/Modal';

// const ServiceWorkerWrapper = () => {
//     const [showReload, setShowReload] = React.useState(false);
//     const [waitingWorker, setWaitingWorker] = React.useState(null);

//     const onSWUpdate = (registration) => {
//         console.log(registration, "registration update");
//         setShowReload(true);
//         countDown();
//         setWaitingWorker(registration.waiting);
//     };

//     useEffect(() => {
//         serviceWorker.register({ onUpdate: onSWUpdate });
//     }, []);

//     const reloadPage = () => {
//         waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
//         setShowReload(false);
//         window.location.reload(true);
//     };

//     const [count, setCount] = useState(5)

//     function countDown() {
//         let secondsToGo = count;
//         const timer = setInterval(() => {
//             secondsToGo -= 1;
//             setCount(secondsToGo)
//         }, 1000);
//         setTimeout(() => {
//             clearInterval(timer);
//             reloadPage()
//         }, secondsToGo * 1000);
//     }

//     return (
//         <Modal footer={false} closable={false} title='New version is available!' visible={showReload} style={{ top: 20 }} okButtonProps={(localStorage.getItem("theme") === "dark") && { style: { color: "black" } }}>
//             <p>A new version is available, installation will start in {count} second</p>
//         </Modal>
//     );
// }

// export default ServiceWorkerWrapper;


// Install Now
import React, { useEffect } from 'react';
import * as serviceWorker from './serviceWorkerRegistration';
import Modal from 'antd/lib/modal/Modal';

const ServiceWorkerWrapper = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState(null);

    const onSWUpdate = (registration) => {
        console.log(registration, "registration update");
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload(true);
    };

    const handleCancel = () => {
        setShowReload(false);
    }

    return (
        <Modal title='New version is available!' visible={showReload} onOk={reloadPage} onCancel={handleCancel} style={{ top: 20 }} okButtonProps={(localStorage.getItem("theme") === "dark") && {style: {color: "black"}}}>
            <p>A new version is available, Install Now to get the new version!</p>
        </Modal>
    );
}

export default ServiceWorkerWrapper;


// // Install Now 