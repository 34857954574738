import Highcharts from 'highcharts';
import millify from 'millify';
import _ from "lodash";
import decimalAdjust from "../../helpers/decimalAdjust";

const milifyNumber = (num) => {
    return millify(num, {
        precision: 2,
        decimalSeparator: '.'
    });
}

const sort = (points) => {
    return (
        _.sortBy(points, [
            function (point) {
                return point.y;
            },
        ]).reverse()
    )
}

const round10 = (value, exp) => decimalAdjust('round', value, exp);

const decideOnColor = (points, name, value, title) => {

    var stdValues = []

    points.map((point, index) => {
        if (point.series.name.toLowerCase().includes("standard")) {
            stdValues.push(point.y)
        }
    })

    if (stdValues.length > 0) {
        var avgSum = 0

        stdValues.map((value, index) => {
            avgSum += value
        })

        var avg = avgSum / (stdValues.length)

        if (name.toLowerCase().includes("standard")) {
            return null
        } else if (title.toLowerCase().includes("loss")) {
            if (value <= avg) {
                return "green"
            } else {
                return "red"
            }
        } else {
            if (value >= avg) {
                return "green"
            } else {
                return "red"
            }
        }
    } else return null

}

const basicLineChartDataGenerator = (chart, toggleLineChartTooltip, index) => {

    var chartHeight = 400

    //change chart height based on yAxis (labels) length, cuz in mobile when labels is too long no room left to both labels and chart
    if (chart.yAxis && chart.yAxis.length) {
        if (chart.yAxis.length < 2) {
            chartHeight = 350
        } else if (chart.yAxis.length < 6) {
            chartHeight = 400
        } else if (chart.yAxis.length < 10) {
            chartHeight = 420
        } else if (chart.yAxis.length < 15) {
            chartHeight = 450
        } else if (chart.yAxis.length < 20) {
            chartHeight = 500
        } else {
            chartHeight = 550
        }
    }


    let chartData = {
        title: {
            text: ''
        },

        legend: {
            itemStyle: {
                fontSize: '15px',
            },
        },

        yAxis: {
            title: {
                text: `${chart.yAxis_title} (${chart.unit})`
            }
        },

        xAxis: {
            categories: chart.xAxis
        },

        plotOptions: {
            series: {
                label: {
                    reversed: false,

                    connectorAllowed: false
                },
                connectNulls: true
            }
        },

        series: chart.yAxis,

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {


                        itemStyle: {
                            fontWeight: 'bold',
                            fontSize: '12px'
                        }
                    },
                    chart: {
                        height: chartHeight,
                    }
                },

            }]
        },

        chart: {
            borderRadius: '12',
            scrollablePlotArea: {
                minWidth: chart.xAxis?.length * 40,
                scrollPositionX: 1,
                opacity: .7
            },
            style: {
                fontFamily: '\'Unica One\', sans-serif'
            },
        },

        tooltip: (() => {
            if (toggleLineChartTooltip.length > 0 && toggleLineChartTooltip[index]) {
                return {
                    formatter: function () {
                        // The first returned item is the header, subsequent items are the
                        // points
                        return '<b>' + this.x + '</b>' + '<table> <tr> <td style="padding:0;width:100%">' + this.series.name + ': </td>' +
                            `<td style="padding:0"><b>` + round10(this.y, -2) + chart.unit + '</b></td></tr> </table>'
                    },
                    // split: true,
                    useHTML: true,
                    shared: false,
                    padding: 5,
                }
            } else {
                return {
                    shared: true,
                    useHTML: true,
                    formatter: function () {
                        return sort(this.points).reduce((s, point) => {
                            return s + '<table> <tr> <td style="padding:0;width:100%">' + point.series.name + ': </td>' +
                                `<td style="padding:0;color:${decideOnColor(this.points, point.series.name, point.y, chart.title)}"><b>` + round10(point.y, -2) + chart.unit + '</b></td></tr> </table>';
                        }, '<b>' + this.x + '</b>');
                    },
                    padding: 5,
                }
            }
        }) (),

        credits: {
            enabled: false,
        }
    }


    return chartData
}

export default basicLineChartDataGenerator;
