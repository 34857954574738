import React from 'react'

import { ReactComponent as TapSvg } from "../../assets/tap.svg";

function InfoMsgHomeSumary(props) {
    
    return (
        <div className={props.show ? "home-page__top__info" : "home-page__top__info-fade"} onClick={()=>props.close(false)}>
            <div className="home-page__top__info__top"><div className="home-page__top__info__top__arr" /></div>
            <div className="home-page__top__info__body"><b>{props.msg}</b><TapSvg /></div>
        </div>
    )
}

export default InfoMsgHomeSumary
