import Types from "../../actions/Types";

const isCum = localStorage.getItem('isCum')

const isComultive = (state = isCum ? JSON.parse(isCum) : false, action) => {
    switch (action.type) {
        case Types.SET_IS_COMULTIVE:
            return action.payload
        case Types.CLEAR_SET_IS_COMULTIVE:
            return false
        default:
            return state;
    }
};

export default isComultive;