import Types from "../../actions/Types";

const daySelected = (state = {name: "Yesterday", value: "Yesterday"}, action) => {
    switch (action.type) {
        case Types.SET_DAY_SELECTED:
            return action.payload
        case Types.CLEAR_SET_DAY_SELECTED:
            return null
        default:
            return state;
    }
};

export default daySelected;