import axios from "axios";
import SecureLS from "secure-ls";

export default () => {

    //getting the user token saved in localstorage and the decrypt it
    var ls = new SecureLS({ encodingType: 'des', isCompression: true, encryptionSecret: '$_!exEcultive-aPp@data*2021.aBuerdAn$$' });
    let token = ls.get('UT')?.data;
    //get organization URL from local storage
    const userUrl = localStorage.getItem('userUrl')
    //set baseURL and add organization URL to base URL
    const baseURL = `https://${userUrl}/api/executive_app`;
    //add token to all APIs headers for authorization
    let headers = { 'Authorization': `Token ${token}` } ;

    const requester = axios.create({
        baseURL: baseURL,
        headers,
    });

    //intercept all APIs responses to check if we got 401 then force logout
    requester.interceptors.response.use(
        (response) =>
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        (error) => {
            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            //if any api response with 401 then the app will force logout 
            if (error.response.status === 401) {
                window.localStorage.removeItem('UT');
                window.location = "/login";
            } else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        }
    );

    return requester;
};