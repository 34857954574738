import React, { useEffect, useState } from 'react';
import millify from 'millify';
import decimalAdjust from "../../helpers/decimalAdjust";
import { Divider } from 'antd';

import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { ReactComponent as HomeSvg } from "../../assets/home.svg";
import { ReactComponent as FirstSvg } from "../../assets/trophy.svg";
import { ReactComponent as LastSvg } from "../../assets/exclamation-mark.svg";

const commaNumber = require('comma-number')

function ReadersSummaryCard({ data, gstate }) {

    const [ageColor, setAgeColor] = useState("#dd3b3b")

    const decideAgeColor = (ageInDays) => {

        //set age color based on age in weeks, but in broilers based on age in days
        if (gstate.moduleSelected && gstate.moduleSelected == "Broilers") {
            if (ageInDays < 20) {
                setAgeColor("#ffa500")
            } else if (ageInDays < 35) {
                setAgeColor("#248ecc")
            } else if (ageInDays >= 35) {
                setAgeColor("#dd3b3b")
            }
        } else {
            let ageInWeeks = ageInDays / 7

            if (ageInWeeks < 16) {
                setAgeColor("#ffa500")
            } else if (ageInWeeks < 25) {
                setAgeColor("#248ecc")
            } else if (ageInWeeks < 50) {
                setAgeColor("#39db80")
            } else if (ageInWeeks >= 50) {
                setAgeColor("#dd3b3b")
            }
        }
    }

    useEffect(() => {
        if (data) {
            decideAgeColor(data.age_in_days)
        }
    }, [data?.age_in_days])

    const milifyNumber = (num) => {
        return millify(num, {
            precision: 2,
            decimalSeparator: '.'
        });
    }

    const round10 = (value, exp) => decimalAdjust('round', value, exp);


    return (
        <div className="readers-summary-card">
            <div className="readers-summary-card__start">
                <div className="readers-summary-card__start__head">
                    <div className="readers-summary-card__start__head__name">
                        {data.name} <span>{data.placement_type}</span>
                        {data.best_farm == true && <div className="readers-summary-card__start__head__name__icon"><FirstSvg /></div>}
                        {data.worst_farm == true && <div className="readers-summary-card__start__head__name__icon-bad">Worst</div>}
                    </div>
                </div>
                <div className="readers-summary-card__start__body">
                    <div className="readers-summary-card__start__body__item">
                        <div className="readers-summary-card__start__body__item__value" style={{ backgroundColor: `${ageColor}`, color: "white" }}>
                            {data.age === parseInt(data.age, 10) ? `${data.age} weeks` : data.age}
                        </div>
                    </div>
                    <div className="readers-summary-card__start__body__v-line" />
                    {data.cost_value ? (
                        <div className="readers-summary-card__start__body__cost-value">
                            {data.cost_value.map((value, index) => {
                                return (
                                    <div className="readers-summary-card__start__body__cost-value__value" key={index}>
                                        <b style={value.class ? { color: value.class } : {}}>{value.name}</b>
                                        <p style={value.class ? { color: value.class } : {}}>{commaNumber(round10(value.value, -2))} {value.unit}</p>
                                    </div>
                                )
                            })}
                        </div>
                    ) : data.live_value ? (
                        <div className="readers-summary-card__start__body__cost-value">
                            {data.live_value.map((value, index) => {
                                return (
                                    <div className="readers-summary-card__start__body__cost-value__value" key={index}>
                                        <b style={{ fontSize: "1.4rem" }}>{value.name}</b>
                                        <p style={{ fontSize: "1.4rem" }}>{commaNumber(round10(value.value, -2))} {index == 1 ? "%" : null}</p>
                                    </div>
                                )
                            })}
                        </div>
                    )
                        : data.total_eggs_value ? (
                            <div className="readers-summary-card__start__body__cost-value">
                                {data.total_eggs_value.map((value, index) => {
                                    return (
                                        <div className="readers-summary-card__start__body__cost-value__value" key={index}>
                                            <b style={{ fontSize: "1.4rem" }}>{value.name}</b>
                                            <p style={{ fontSize: "1.4rem" }}>{commaNumber(round10(value.value, -2))}{value.unit ? value.unit : null}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                            : data.amount_value ? (
                                <div className="readers-summary-card__start__body__cum-sales">
                                    {data.amount_value?.length > 0 && data.amount_value.map((value, index) => {
                                        return (
                                            <div className="readers-summary-card__start__body__cum-sales__value" key={index}>
                                                <b>{value.name}</b>
                                                <p>{commaNumber(round10(value.value, -2))}{value.unit ? value.unit : null}</p>
                                            </div>
                                        )
                                    })}
                                    {data.amount_value_details?.length > 0 && data.amount_value_details.map((value, index) => {
                                        return (
                                            <div className="readers-summary-card__start__body__cum-sales__details" key={index}>
                                                <Divider orientation="center">
                                                    {value.name}
                                                </Divider>
                                                <div className="readers-summary-card__start__body__cum-sales__details__body">
                                                    <div className="readers-summary-card__start__body__cum-sales__details__body__section">
                                                        <b>Amount</b>
                                                        <p>{commaNumber(round10(value.amount, -2))}{value.unit ? value.unit : null}</p>
                                                    </div>
                                                    <div className="readers-summary-card__start__body__cum-sales__details__body__section">
                                                        <b>Price</b>
                                                        <p>{commaNumber(round10(value.price, -2))}{value.unit ? value.unit : null}</p>
                                                    </div>
                                                    <div className="readers-summary-card__start__body__cum-sales__details__body__section">
                                                        <b>Sales Qnt</b>
                                                        <p>{commaNumber(round10(value.sales_quantity, -2))}{value.unit ? value.unit : null}</p>
                                                    </div>
                                                    <div className="readers-summary-card__start__body__cum-sales__details__body__section">
                                                        <b>Total Weight</b>
                                                        <p>{commaNumber(round10(value.total_weight, -2))}{value.unit ? value.unit : null}</p>
                                                    </div>
                                                    <div className="readers-summary-card__start__body__cum-sales__details__body__section">
                                                        <b>LB Weight</b>
                                                        <p>{commaNumber(round10(value.lb_weight, -2))}{value.unit ? value.unit : null}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                                : (
                                    <div className="readers-summary-card__start__body__value">
                                        <b>{commaNumber(round10(data.value, -2))} {data.unit}</b>
                                    </div>
                                )}


                </div>
            </div>
            <div className="readers-summary-card__end">
                {data.cost_value ? (
                    <div className="readers-summary-card__end__cost-value">
                        {data.cost_value.map((value, index) => {
                            return (
                                <div className="readers-summary-card__end__cost-value__value" key={index}>
                                    <b style={index == 3 || index == 4 ? { color: "#248ecc" } : {}}>{value.name}</b>
                                    <p style={index == 3 || index == 4 ? { color: "#248ecc" } : {}}>{commaNumber(round10(value.value, -2))} {index == 3 || index == 4 ? "KG" : "EGP"}</p>
                                </div>
                            )
                        })}
                    </div>
                ) : data.live_value ? (
                    <div className="readers-summary-card__end__cost-value">
                        {data.live_value.map((value, index) => {
                            return (
                                <div className="readers-summary-card__end__cost-value__value" key={index}>
                                    <b style={{ fontSize: "1.5rem" }}>{value.name}</b>
                                    <p style={{ fontSize: "1.5rem" }}>{commaNumber(round10(value.value, -2))} {index == 1 ? "%" : null}</p>
                                </div>
                            )
                        })}
                    </div>
                ) : data.total_eggs_value ? (
                    <div className="readers-summary-card__end__cost-value">
                        {data.total_eggs_value.map((value, index) => {
                            return (
                                <div className="readers-summary-card__end__cost-value__value" key={index}>
                                    <b style={{ fontSize: "1.4rem", marginRight: "10px" }}>{value.name}</b>
                                    <p style={{ fontSize: "1.4rem" }}>{commaNumber(round10(value.value, -2))} {value.unit ? value.unit : null}</p>
                                </div>
                            )
                        })}
                    </div>
                )
                    : data.amount_value ? (
                        <div className="readers-summary-card__end__cum-sales">
                            {data.amount_value?.length > 0 && data.amount_value.map((value, index) => {
                                return (
                                    <div className="readers-summary-card__end__cum-sales__value" key={index}>
                                        <b>{value.name}</b>
                                        <p>{commaNumber(round10(value.value, -2))}{value.unit ? value.unit : null}</p>
                                    </div>
                                )
                            })}
                            {data.amount_value_details?.length > 0 && data.amount_value_details.map((value, index) => {
                                return (
                                    <div className="readers-summary-card__end__cum-sales__details" key={index}>
                                        <Divider orientation="center">
                                            {value.name}
                                        </Divider>
                                        <div className="readers-summary-card__end__cum-sales__details__body">
                                            <div className="readers-summary-card__end__cum-sales__details__body__section">
                                                <b>Amount</b>
                                                <p>{commaNumber(round10(value.amount, -2))}{value.unit ? value.unit : null}</p>
                                            </div>
                                            <div className="readers-summary-card__end__cum-sales__details__body__section">
                                                <b>Price</b>
                                                <p>{commaNumber(round10(value.price, -2))}{value.unit ? value.unit : null}</p>
                                            </div>
                                            <div className="readers-summary-card__end__cum-sales__details__body__section">
                                                <b>Sales Qnt</b>
                                                <p>{commaNumber(round10(value.sales_quantity, -2))}{value.unit ? value.unit : null}</p>
                                            </div>
                                            <div className="readers-summary-card__end__cum-sales__details__body__section">
                                                <b>Total Weight</b>
                                                <p>{commaNumber(round10(value.total_weight, -2))}{value.unit ? value.unit : null}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                        : (
                            <div className="readers-summary-card__end__value">
                                <b>{commaNumber(round10(data.value, -2))} {data.unit}</b>
                            </div>
                        )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {

})(React.memo(ReadersSummaryCard));