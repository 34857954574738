//this helper functions takes overview data and then generate the notifications array,
//It select the best Farm and Worst Farm from Breeders and Broilers only
function generateNotifications(overViewData) {

    let notificationsBody = []

    if (overViewData?.Today?.Breeders?.rearing_farms?.length > 0) {
        overViewData?.Today?.Breeders?.rearing_farms.map((farm, index) => {
            if (farm.best_farm || farm.worst_farm) {
                notificationsBody.push({
                    farmName: farm.name,
                    bestFarm: farm.best_farm ? true : false,
                    worstFarm: farm.worst_farm ? true : false,
                    moduleName: "Breeders",
                    link: `/farm-details/${farm.id}/Breeders/Today`,
                    farmManager: farm.house_manager,
                    field: "Rearing farms"
                })
            }
        })
    }
    if (overViewData?.Today?.Breeders?.production_farms?.length > 0) {
        overViewData?.Today?.Breeders?.production_farms.map((farm, index) => {
            if (farm.best_farm || farm.worst_farm) {
                notificationsBody.push({
                    farmName: farm.name,
                    bestFarm: farm.best_farm ? true : false,
                    worstFarm: farm.worst_farm ? true : false,
                    moduleName: "Breeders",
                    link: `/farm-details/${farm.id}/Breeders/Today`,
                    farmManager: farm.house_manager,
                    field: "Production farms"
                })
            }
        })
    }
    if (overViewData?.Today?.Broilers?.farms?.length > 0) {
        overViewData?.Today?.Broilers?.farms.map((farm, index) => {
            if (farm.best_farm || farm.worst_farm) {
                notificationsBody.push({
                    farmName: farm.name,
                    bestFarm: farm.best_farm ? true : false,
                    worstFarm: farm.worst_farm ? true : false,
                    moduleName: "Broilers",
                    link: `/farm-details/${farm.id}/Broilers/Today`,
                    farmManager: farm.house_manager,
                    field: null
                })
            }
        })
    }

    return notificationsBody
}

export default generateNotifications