import Types from "../../actions/Types";

const tour = (state = false, action) => {
    switch (action.type) {
        case Types.START_TOUR:
            return action.payload
        case Types.CLEAR_START_TOUR:
            return false
        default:
            return state;
    }
};

export default tour;