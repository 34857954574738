import React, {Fragment, useEffect, useState} from 'react'
import ReadersSummaryCard from "../readersSummary/ReadersSummaryCard";
import _ from "lodash";

function LayersProductionHouses({houses}) {
    const [filteredData, setFilteredData] = useState()
    useEffect(() => {
        if (houses) {
            setFilteredData(
                _.sortBy(houses, [
                    function (house) {
                        return house.age_in_days;
                    },
                ]).reverse()
            );
        }
    }, [houses])
    return (
        <Fragment>
            <div className="home-page__top__title">
                Houses Production
            </div>
            <div className="farm-details__body__cards">
                {
                    filteredData?.length > 0 &&
                    filteredData.map((card, index) =>
                        <ReadersSummaryCard data={card} key={index}/>)
                }
            </div>
        </Fragment>
    )
}

export default LayersProductionHouses
