import Types from "../../actions/Types";

import data from "./farmDetails.json"

const farmDetails = (state = null, action) => {
    switch (action.type) {
        case Types.GET_FARM_DETAILS:
            return action.payload
        case Types.CLEAR_GET_FARM_DETAILS:
            return null
        default:
            return state;
    }
};

export default farmDetails;