import Types from "../../actions/Types";

const stopScrolling = (state = false, action) => {
    switch (action.type) {
        case Types.STOP_SCROLLING:
            return action.payload
        case Types.CLEAR_STOP_SCROLLING:
            return false
        default:
            return state;
    }
};

export default stopScrolling;