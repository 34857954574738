import React, { Fragment, useEffect, useState } from 'react'
import { ReactComponent as FilterSvg } from "../../assets/filter.svg";
import { ReactComponent as CheckedSvg } from "../../assets/checked.svg";
import { ReactComponent as CanceldSvg } from "../../assets/cancel.svg";
import { connect } from "react-redux";

import {
    SET_DAY_SELECTED,
    SET_IS_COMULTIVE,
} from "../../redux/actions/appData/index"

import {
    CLEAR_GET_OVERVIEW_DATA
} from "../../redux/actions/overView/index"

import {
    CLEAR_GET_FARM_DETAILS
} from "../../redux/actions/farmDetails/index"

import {
    CLEAR_GET_READERS_SUMMARY
} from "../../redux/actions/readersSummary/index"

function DayPopUp(props) {

    const [day, setDay] = useState()
    const [isCum, setIsCum] = useState(false)

    const filter = () => {
        props.setIsPopUpOpen(false)
        props.SET_DAY_SELECTED({ name: day, value: day })
        props.SET_IS_COMULTIVE(isCum)
        localStorage.setItem("isCum", isCum);
        localStorage.setItem("daySelected", day);

        if (props.gstate.isComultive != isCum) {
            props.CLEAR_GET_OVERVIEW_DATA()
            props.CLEAR_GET_FARM_DETAILS()
            props.CLEAR_GET_READERS_SUMMARY()
        }
    }

    const restData = () => {
        setDay(props.gstate.daySelected.value)
        setIsCum(props.gstate.isComultive)
    }

    useEffect(() => {
        if (props.gstate.daySelected.value && (props.gstate.isComultive == false || props.gstate.isComultive == true)) {
            setDay(props.gstate.daySelected.value);
            setIsCum(props.gstate.isComultive);
        }
    }, [props.gstate.daySelected.value, props.gstate.isComultive, props.isPopUpOpen])

    return (
        <Fragment>
            <div className="overlay-popup" onClick={() => { props.setIsPopUpOpen(false); setIsCum(props.gstate.isComultive.value); setDay(props.gstate.daySelected.value) }} style={!props.isPopUpOpen ? { display: "none" } : {}} />
            <div className="day-popup" style={!props.isPopUpOpen ? { display: "none" } : {}} id="tour-day-filter-popup">
                <div className="day-popup__title">
                    <FilterSvg /> <span>Filters Options</span>
                </div>

                <div className="day-popup__group-title">
                    Filter by Today/Yesterday
                </div>
                <div className="day-popup__group">
                    <div className="day-popup__group__check-box" onClick={() => setDay("Today")}>
                        <button className={day == "Today" ? "btn btn__day-popup" : "btn btn__day-popup-not-active"}>Today</button>
                    </div>

                    <div className="day-popup__group__check-box" onClick={() => setDay("Yesterday")}>
                        <button className={day == "Yesterday" ? "btn btn__day-popup" : "btn btn__day-popup-not-active"}>Yesterday</button>
                    </div>
                </div>

                <div className="day-popup__line" />

                <div className="day-popup__group-title">
                    Filter by Single/Cumulative
                </div>
                <div className="day-popup__group">
                    <div className="day-popup__group__check-box" onClick={() => setIsCum(false)}>
                        <button className={!isCum ? "btn btn__day-popup" : "btn btn__day-popup-not-active"}>Single</button>
                    </div>

                    <div className="day-popup__group__check-box" onClick={() => setIsCum(true)}>
                        <button className={isCum ? "btn btn__day-popup" : "btn btn__day-popup-not-active"}>Cumulative</button>
                    </div>
                </div>

                <div className="day-popup__btns">
                    <div className="day-popup__btns__btn-reset" onClick={() => {props.setIsPopUpOpen(false); restData()}}>
                        <CanceldSvg />
                        <span>Cancel</span>
                    </div>
                    <div className="day-popup__btns__btn-apply" onClick={filter}>
                        <CheckedSvg />
                        <span>Apply</span>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    SET_DAY_SELECTED,
    SET_IS_COMULTIVE,
    CLEAR_GET_OVERVIEW_DATA,
    CLEAR_GET_FARM_DETAILS,
    CLEAR_GET_READERS_SUMMARY,
})(DayPopUp);
