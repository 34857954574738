import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import millify from 'millify';
import decimalAdjust from '../../helpers/decimalAdjust';
import { ReactComponent as UserSvg } from "../../assets/userFill.svg";
import { ReactComponent as FirstSvg } from "../../assets/trophy.svg";

function BreedersFarmCard({ data, gstate }) {

    //set age color based on age in weeks
    const decideAgeColor = (ageInDays) => {
        let ageInWeeks = ageInDays / 7

        if (ageInWeeks < 16) {
            return "#ffa500"
        } else if (ageInWeeks < 25) {
            return "#248ecc"
        } else if (ageInWeeks < 50) {
            return "#39db80"
        } else if (ageInWeeks >= 50) {
            return "#dd3b3b"
        }
    }

    const round10 = (value, exp) => decimalAdjust('round', value, exp);

    const milifyNumber = (num) => {
        return millify(num, {
            precision: 2,
            decimalSeparator: '.'
        });
    }

    return (
        <Link to={`/farm-details/${gstate.moduleSelected}/${gstate.daySelected.value}/${data.id}`} className="breeders-farm-card" id="tour-homePageFarmCard" id="tour-homePageFarmsDataCard">
            <div className="breeders-farm-card__start">
                <div className="breeders-farm-card__start__head">
                    <div className="breeders-farm-card__start__head__name">
                        {data.name} <span>{data.placement_type}</span>
                        {data.best_farm == true && <div className="breeders-farm-card__start__head__name__icon"><FirstSvg /></div>}
                        {data.worst_farm == true && <div className="breeders-farm-card__start__head__name__icon-bad">Worst</div>}
                    </div>
                </div>
                <div className="breeders-farm-card__start__body">
                    <div className="breeders-farm-card__start__body__item">
                        <div className="breeders-farm-card__start__body__item__value" style={{ backgroundColor: `${data?.age_in_days ? decideAgeColor(data.age_in_days) : "#dd3b3b"}`, color: "white" }}>
                            {data.age}
                        </div>
                    </div>
                    <div className="breeders-farm-card__start__body__v-line" />
                    {data.feed_per_bird || data.feed_per_bird == 0 ? (
                        <div className="breeders-farm-card__end__data__item remove-on-900">
                            <div className="breeders-farm-card__end__data__item__data">
                                {data.feed_per_bird >= data.st_feed ? (
                                    <b style={{ color: "#39db80" }}>{data.feed_per_bird && milifyNumber(data.feed_per_bird)} gm</b>
                                ) : <b style={{ color: "red" }}>{data.feed_per_bird && milifyNumber(data.feed_per_bird)}gm</b>
                                }
                                <span>Feed/Bird</span>
                            </div>
                            <div className="breeders-farm-card__end__data__item__std">
                                <b>{data.st_feed && milifyNumber(data.st_feed)}gm</b>
                                <span>std</span>
                            </div>
                        </div>
                    ) : (
                        <div className="breeders-farm-card__end__data__item remove-on-900">
                            <div className="breeders-farm-card__end__data__item__data">
                                {data.total_eggs >= data.st_total_eggs ? (
                                    <b style={{ color: "#39db80" }}>{round10(data.total_eggs, -2)}</b>
                                ) : <b style={{ color: "red" }}>{round10(data.total_eggs, -2)}</b>
                                }
                                <span>Total eggs/HH</span>
                            </div>
                            <div className="breeders-farm-card__end__data__item__std">
                                <b>{round10(data.st_total_eggs, -2)}</b>
                                <span>std</span>
                            </div>
                        </div>
                    )}
                    {data.breeds?.length > 0 && (
                        <div className="breeders-farm-card__start__breeds">
                            {data.breeds.map((breed, index) => {
                                return <div className="breeders-farm-card__start__breeds__breed" key={index}>{breed}</div>
                            })}
                        </div>
                    )}
                </div>
            </div>
            <div className="breeders-farm-card__end">
                <div className="breeders-farm-card__end__data">
                    <div className="breeders-farm-card__end__data__item">
                        <div className="breeders-farm-card__end__data__item__data">
                            {data.weight >= data.st_weight ? (
                                <b style={{ color: "#39db80" }}>{round10(data.weight, -2)}kg</b>
                            ) : <b style={{ color: "red" }}>{round10(data.weight, -2)}kg</b>
                            }
                            <span>Weight</span>
                        </div>
                        <div className="breeders-farm-card__end__data__item__std">
                            <b>{round10(data.st_weight, -2)}kg</b>
                            <span>std</span>
                        </div>
                    </div>
                    <div className="breeders-farm-card__start__body__v-line" />
                    <div className="breeders-farm-card__end__data__item">
                        <div className="breeders-farm-card__end__data__item__data">
                            {data.loss_percent <= data.st_loss_percent ? (
                                <b style={{ color: "#39db80" }}>{round10(data.loss_percent, -2)}%</b>
                            ) : <b style={{ color: "red" }}>{round10(data.loss_percent, -2)}%</b>
                            }
                            <span>Loss</span>
                        </div>
                        <div className="breeders-farm-card__end__data__item__std">
                            <b>{round10(data.st_loss_percent, -2)}%</b>
                            <span>std</span>
                        </div>
                    </div>
                    {data.feed_per_bird || data.feed_per_bird == 0 ? (
                        <div className="breeders-farm-card__end__data__item remove-greater-900">
                            <div className="breeders-farm-card__end__data__item__data">
                                {data.feed_per_bird >= data.st_feed ? (
                                    <b style={{ color: "#39db80" }}>{data.feed_per_bird && milifyNumber(data.feed_per_bird)} gm</b>
                                ) : <b style={{ color: "red" }}>{data.feed_per_bird && milifyNumber(data.feed_per_bird)}gm</b>
                                }
                                <span>Feed/Bird</span>
                            </div>
                            <div className="breeders-farm-card__end__data__item__std">
                                <b>{data.st_feed && milifyNumber(data.st_feed)}gm</b>
                                <span>std</span>
                            </div>
                        </div>
                    ) : (
                        <div className="breeders-farm-card__end__data__item remove-greater-900">
                            <div className="breeders-farm-card__end__data__item__data">
                                {data.total_eggs >= data.st_total_eggs ? (
                                    <b style={{ color: "#39db80" }}>{round10(data.total_eggs, -2)}</b>
                                ) : <b style={{ color: "red" }}>{round10(data.total_eggs, -2)}</b>
                                }
                                <span>Total Eggs/HH</span>
                            </div>
                            <div className="breeders-farm-card__end__data__item__std">
                                <b>{round10(data.st_total_eggs, -2)}</b>
                                <span>std</span>
                            </div>
                        </div>
                    )}

                </div>
                {data.breeds?.length > 0 && (
                    <div className="breeders-farm-card__end__breeds">
                        {data.breeds.map((breed, index) => {
                            return <div className="breeders-farm-card__end__breeds__breed" key={index}>{breed}</div>
                        })}
                    </div>
                )}
                {data.house_manager?.length > 0 && (
                    <div className="breeders-farm-card__end__foot">
                        <div className="breeders-farm-card__end__foot__name" id="tour-farm-card-managername">
                            <UserSvg />
                            {data.house_manager.length == 1 ? data.house_manager[0] : `${data.house_manager[0]} ...`}
                        </div>
                    </div>
                )}
            </div>
        </Link>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {

})(React.memo(BreedersFarmCard));