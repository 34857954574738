import React from 'react'
import { Skeleton } from 'antd';

function Loading() {
    return (
        <Skeleton active />
    )
}

export default Loading
