import Types from "../../actions/Types";

const overViewData = (state = null, action) => {
    switch (action.type) {
        case Types.GET_OVERVIEW_DATA:
            return action.payload
        case Types.CLEAR_GET_OVERVIEW_DATA:
            return null
        default:
            return state;
    }
};

export default overViewData;