import Types from "../../actions/Types";

const moduleSelected = (state = null, action) => {
    switch (action.type) {
        case Types.SET_MODULE_SELECTED:
            return action.payload
        case Types.CLEAR_SET_MODULE_SELECTED:
            return null
        default:
            return state;
    }
};

export default moduleSelected;