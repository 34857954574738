import React, { useState, Fragment } from 'react'
import millify from 'millify';
import { Link } from 'react-router-dom';
import decimalAdjust from "../../helpers/decimalAdjust";

// import { ReactComponent as UpArSvg } from "../../assets/up-arrow.svg";
// import { ReactComponent as DownArSvg } from "../../assets/down-arrow.svg";
import { ReactComponent as PencilSvg } from "../../assets/pencil.svg";
import { ReactComponent as PlussSvg } from "../../assets/pluss.svg";
import { ReactComponent as DeleteSvg } from "../../assets/close.svg";

function HomePageReadersSummaryCard({ field, index, moduleSelected, openDeleteButtons, monthly }) {
    const milifyNumber = (num) => {
        return millify(num, {
            precision: 3,
            decimalSeparator: '.'
        });
    }
    
    const commaNumber = require('comma-number')

    const [optionsIsOpen, setOptionsIsOpen] = useState(false)

    const round10 = (value, exp) => decimalAdjust('round', value, exp);

    return (
        <Fragment>
            <div className="home-page__top__details__detail" key={index}>
                {/* <div className="home-page__top__details__detail__start">
                    <div className={field.in_progress ? "home-page__top__details__detail__start__up" : "home-page__top__details__detail__start__down"}>
                        {field.in_progress == true && <UpArSvg />}
                        {field.in_progress == false && <DownArSvg />}
                    </div>
                </div> */}

                {/* <div className="home-page__top__details__detail__options" onClick={() => setOptionsIsOpen(!optionsIsOpen)}>
                    <PencilSvg />
                </div>
                <div className={optionsIsOpen ? "home-page__top__details__detail__options-menu" : "home-page__top__details__detail__options-menu-closed"}>
                    <div className={optionsIsOpen ? "home-page__top__details__detail__options-menu__item" : "home-page__top__details__detail__options-menu-closed__item"} onClick={() => { setIsPopUpOpen(true); }}>
                        <PlussSvg />
                    </div>
                    <div className={optionsIsOpen ? "home-page__top__details__detail__options-menu__item" : "home-page__top__details__detail__options-menu-closed__item"} onClick={() => { console.log("CLICK"); }}>
                        <DeleteSvg />
                    </div>
                </div> */}
                {openDeleteButtons && (
                    <div className="home-page__top__details__detail__options fade-in">
                        <DeleteSvg />
                    </div>
                )}
                <Link to={`/readers-summary/${moduleSelected}/${index}/${monthly ? 'monthly' : 'running'}`} className="home-page__top__details__detail__end">
                    <div className="home-page__top__details__detail__end__name one-line">{field.name}</div>
                    <div className="home-page__top__details__detail__end__num one-line" style={field.in_progress == true ? { color: "#39db80" } : field.in_progress == false ? { color: "red" } : {}}>
                        {field.value && (field.unit?.toLowerCase() == "kg" ? commaNumber(round10(field.value, -2)) : milifyNumber(field.value))} {" "} {field.unit}
                    </div>
                </Link>
            </div>
        </Fragment>
    )
}

export default React.memo(HomePageReadersSummaryCard)
