import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import millify from 'millify';

import { ReactComponent as HomeSvg } from "../../assets/home.svg";
import { ReactComponent as FirstSvg } from "../../assets/trophy.svg";
import { ReactComponent as LastSvg } from "../../assets/exclamation-mark.svg";

function HatcheryFarmCard({ data, gstate }) {
    //set age color based on age in weeks
    const decideAgeColor = (ageInDays) => {
        let ageInWeeks = ageInDays / 7

        if (ageInWeeks < 16) {
            return "#ffa500"
        } else if (ageInWeeks < 25) {
            return "#248ecc"
        } else if (ageInWeeks < 50) {
            return "#39db80"
        } else if (ageInWeeks >= 50) {
            return "#dd3b3b"
        }
    }

    const milifyNumber = (num) => {
        return millify(num, {
            precision: 2,
            decimalSeparator: '.'
        });
    }

    return (
        <div className="hatchery-farm-card" id="tour-homePageFarmCard" id="tour-homePageFarmsDataCard">
            <div className="hatchery-farm-card__start">
                <div className="hatchery-farm-card__start__head">
                    <div className="hatchery-farm-card__start__head__name">
                        {data.name} <span>{data.placement_type}</span>
                        {data.best_farm == true && <div className="hatchery-farm-card__start__head__name__icon"><FirstSvg /></div>}
                        {data.worst_farm == true && <div className="hatchery-farm-card__start__head__name__icon-bad">Worst</div>}
                    </div>
                </div>
                <div className="hatchery-farm-card__start__body">
                    <div className="hatchery-farm-card__start__body__age">
                        <div className="hatchery-farm-card__start__body__age__value" style={{ backgroundColor: `${data?.age_in_days ? decideAgeColor(data.age_in_days) : "#dd3b3b"}`, color: "white" }}>
                            {data.age}
                        </div>
                    </div>
                    <div className="hatchery-farm-card__start__body__v-line" />
                    <div className="hatchery-farm-card__start__body__item">
                        <div className="hatchery-farm-card__start__body__item__data">
                            <b>{data.inspected_eggs && milifyNumber(data.inspected_eggs)}</b>
                            <span>inspected eggs</span>
                        </div>
                        <div className="hatchery-farm-card__start__body__item__data">
                            <b>{data.egg_sets && milifyNumber(data.egg_sets)}</b>
                            <span>egg sets</span>
                        </div>
                        <div className="hatchery-farm-card__start__body__item__data">
                            <b>{data.output_chicks && milifyNumber(data.output_chicks)}</b>
                            <span>output chicks</span>
                        </div>
                        <div className="hatchery-farm-card__start__body__item__data">
                            <b>{data.hatchability && milifyNumber(data.hatchability)}</b>
                            <span>hatchability</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hatchery-farm-card__end">
                <div className="hatchery-farm-card__end__data">
                    <div className="hatchery-farm-card__end__data__item">
                        <div className="hatchery-farm-card__end__data__item__data">
                            <b>{data.inspected_eggs && milifyNumber(data.inspected_eggs)}</b>
                            <span>inspected eggs</span>
                        </div>
                        <div className="hatchery-farm-card__end__data__item__data">
                            <b>{data.egg_sets && milifyNumber(data.egg_sets)}</b>
                            <span>egg sets</span>
                        </div>
                        <div className="hatchery-farm-card__end__data__item__data">
                            <b>{data.output_chicks && milifyNumber(data.output_chicks)}</b>
                            <span>output chicks</span>
                        </div>
                        
                        <div className="hatchery-farm-card__end__data__item__data">
                            <b>{data.hatchability && milifyNumber(data.hatchability)}</b>
                            <span>hatchability</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {

})(React.memo(HatcheryFarmCard));