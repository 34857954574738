import Types from "../../actions/Types";

const startTourPopUp = (state = false, action) => {
    switch (action.type) {
        case Types.START_TOUR_POPUP:
            return action.payload
        case Types.CLEAR_START_TOUR_POPUP:
            return false
        default:
            return state;
    }
};

export default startTourPopUp;