import { combineReducers } from "redux";

import userData from "./registration/userData";
import login from "./registration/login";

import overViewData from "./overView/overViewData";
import getOverviewDataError from "./overView/getOverviewDataError";

import readersSummaryCards from "./readersSummary/readersSummaryCards";
import readersSummary from "./readersSummary/readersSummary";
import readersSummaryEditCards from "./readersSummary/readersSummaryEditCards";
import readersSummaryUpdate from "./readersSummary/readersSummaryUpdate";
import readersSummaryHouseCards from "./readersSummary/readersSummaryHouseCards";
import readersSummaryHouseEditCards from "./readersSummary/readersSummaryHouseEditCards";

import farmDetails from "./farmDetails/farmDetails";

import moduleSelected from "./appData/moduleSelected";
import moduleDataSelected from "./appData/moduleDataSelected";
import daySelected from "./appData/daySelected";
import isComultive from "./appData/isComultive";
import showInfoMsgHome from "./appData/showInfoMsgHome";
import stopScrolling from "./appData/stopScrolling";
import startTourPopUp from "./appData/startTourPopUp"
import notificationData from "./appData/notificationData"
import getDataError from "./appData/getDataError"

import tour from "./tour/tour";

export default combineReducers({
  
  //registration
  userData,
  login,

  //overview
  overViewData,
  getOverviewDataError,

  //readers summary
  readersSummaryCards,
  readersSummary,
  readersSummaryEditCards,
  readersSummaryUpdate,
  readersSummaryHouseCards,
  readersSummaryHouseEditCards,

  //farm details
  farmDetails,

  //app data
  moduleSelected,
  moduleDataSelected,
  daySelected,
  isComultive,
  showInfoMsgHome,
  stopScrolling,
  startTourPopUp,
  notificationData,
  getDataError,

  //tour
  tour,

});