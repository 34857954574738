import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import HouseCard from './HouseCard'
import _ from "lodash";
import { ReactComponent as SortSvg } from "../../assets/descendant.svg";
import Dropdown from 'react-dropdown';

function HousesDataField({houses, moduleSelected}) {

    function handleChange(value) {
        if (value.value == "Sort by age") {
            filterByAge()
        } else if (value.value == "Sort by weight") {
            filterByWight()
        } else if (value.value == "Alpha a-z") {
            filterByAlpha()
        } else {
            filterByAge()
        }
    }

    const options = [
        'Sort by age', 'Sort by weight', 'Alpha a-z'
    ];

    const [filteredData, setFilteredData] = useState()
    
    const filterByAge = () => {
        setFilteredData(
            _.sortBy(houses, [
                function (house) {
                    return house.age_in_days;
                },
            ]).reverse()
        );
    }

    const filterByWight = () => {
        setFilteredData(
            _.sortBy(houses, [
                function (house) {
                    return house.weight;
                },
            ]).reverse()
        );
    }

    const filterByAlpha = () => {
        setFilteredData(
            _.sortBy(houses, [
                function (house) {
                    return house.name;
                },
            ])
        );
    }

    useEffect(() => {
        if (houses) {
            setFilteredData(
                _.sortBy(houses, [
                    function (house) {
                        return house.age_in_days;
                    },
                ]).reverse()
            );
        }
    }, [houses])

    return (
        <Fragment>
            <div className="home-page__top__title">
                Houses
                
                <div className="home-page__top__title__dropdown">
                    <Dropdown options={options} onChange={handleChange} value={options[0]} placeholder="Select an option" />
                </div>
            </div>
            <div className="farm-details__body__cards">
                {filteredData?.length > 0 && filteredData.map((house, index) => {
                    return <HouseCard data={house} key={index} moduleSelected={moduleSelected} />
                })}
            </div>
        </Fragment>
    )
}

export default HousesDataField
