import React from 'react'
import millify from 'millify';
import decimalAdjust from "../../helpers/decimalAdjust";

import { ReactComponent as DeleteSvg } from "../../assets/close.svg";


function FarmDetailsReadersSummaryCard({ field, index, openDeleteButtons }) {

    const milifyNumber = (num) => {
        return millify(num, {
            precision: 2,
            decimalSeparator: '.'
        });
    }

    const milifyNumber3dec = (num) => {
        return millify(num, {
            precision: 3,
            decimalSeparator: '.'
        });
    }

    const commaNumber = require('comma-number')

    const round10 = (value, exp) => decimalAdjust('round', value, exp);

    return (
        <div className="home-page__top__details__detail" key={index}>
            {openDeleteButtons && (
                <div className="home-page__top__details__detail__options fade-in">
                    <DeleteSvg />
                </div>
            )}
            <div className="home-page__top__details__detail__end">
                <div className="home-page__top__details__detail__end__name one-line">{field.name}</div>
                {field.unit?.toLowerCase() == "kg" ? (
                    <div className="home-page__top__details__detail__end__num one-line" style={field.in_progress == true ? { color: "#39db80" } : field.in_progress == false ? { color: "red" } : {}}>
                        {field.value && commaNumber(round10(field.value, -3))}{" "} {field.unit}
                    </div>
                ) : (
                    <div className="home-page__top__details__detail__end__num one-line" style={field.in_progress == true ? { color: "#39db80" } : field.in_progress == false ? { color: "red" } : {}}>
                        {field.name.toLowerCase() == "fcr" ? field.value && milifyNumber3dec(field.value) : field.value && milifyNumber(field.value)}
                        {" "} {field.unit}
                    </div>
                )}
            </div>
        </div>
    )
}

export default FarmDetailsReadersSummaryCard
