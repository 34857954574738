import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import decimalAdjust from '../../helpers/decimalAdjust';
import { ReactComponent as FirstSvg } from "../../assets/trophy.svg";
import { ReactComponent as UserSvg } from "../../assets/userFill.svg";

function HouseCard({ data, moduleSelected }) {
    const isCum = localStorage.getItem('isCum')

    const decideAgeColor = (ageInDays) => {
        let ageInWeeks = ageInDays/7

        //set age color based on age in weeks, but in broilers based on age in days
        if(moduleSelected?.toLowerCase() == "broilers") {
            if (ageInDays < 20) {
                return "#ffa500"
            } else if (ageInDays < 35) {
                return "#248ecc"
            } else if (ageInDays >= 35) {
                return "#dd3b3b"
            }
        } else {
            if(ageInWeeks < 16) {
                return "#ffa500"
            } else if(ageInWeeks < 25) {
                return "#248ecc"
            } else if(ageInWeeks < 50) {
                return "#39db80"
            } else if(ageInWeeks >= 50) {
                return "#dd3b3b"
            } 
        }
    }

    const round10 = (value, exp) => decimalAdjust('round', value, exp);


    return (
        <div className="house-card" id="tour-homePageFarmCard">
            <div className="house-card__start">
                <div className="house-card__start__head">
                    <div className="house-card__start__head__name">
                        {data.name} <span>{data.placement_type}</span>
                        {data.best_farm == true && <div className="house-card__start__head__name__icon"><FirstSvg /></div>}
                        {data.worst_farm == true && <div className="house-card__start__head__name__icon-bad">Worst</div>}
                    </div>
                </div>
                <div className="house-card__start__body">
                    <div className="house-card__start__body__item">
                        <div className="house-card__start__body__item__value" style={{backgroundColor:`${data?.age_in_days ? decideAgeColor(data.age_in_days) : "#dd3b3b"}`, color:"white"}}>
                            {data.age}
                        </div>
                    </div>
                
                </div>
            </div>
            <div className="house-card__end">
                <div className="house-card__end__data">
                    <div className="house-card__end__data__item">
                        <div className="house-card__end__data__item__data">
                            {data.weight >= data.st_weight ? (
                                <b style={{ color: "#39db80" }}>{round10(data.weight, -3)}kg</b>
                            ) : <b style={{ color: "red" }}>{round10(data.weight, -3)}kg</b>
                            }
                            <span>Weight</span>
                        </div>
                        <div className="house-card__end__data__item__std">
                            <b>{round10(data.st_weight, -3)}kg</b>
                            <span>std</span>
                        </div>
                    </div>
                    <div className="house-card__start__body__v-line" />
                    <div className="house-card__end__data__item">
                        <div className="house-card__end__data__item__data">
                            {data.loss_percent <= data.st_loss_percent ? (
                                <b style={{ color: "#39db80" }}>{round10(data.loss_percent, -3)}%</b>
                            ) : <b style={{ color: "red" }}>{round10(data.loss_percent, -3)}%</b>
                            }
                            <span>Loss</span>
                        </div>
                        <div className="house-card__end__data__item__std">
                            <b>{round10(data.st_loss_percent, -3)}%</b>
                            <span>std</span>
                        </div>
                    </div>
                </div>
                {moduleSelected.toLowerCase() === "breeders" &&
                <div className="house-card__end__dataSecondRow">
                    <div className="house-card__end__data__item">
                        <div className="house-card__end__data__item__data">
                            {data.eggs_production >= data.st_eggs_production ? (
                                <b style={{ color: "#39db80" }}>{round10(data.eggs_production, -3)}</b>
                            ) : <b style={{ color: "red" }}>{round10(data.eggs_production, -3)}</b>
                            }
                            <span>Eggs</span>
                        </div>
                        <div className="house-card__end__data__item__std">
                        
                        <b>{round10(data.st_eggs_production, -3)}
                        {isCum === "false" &&
                            <label>%</label>
                        }
                        </b>
                            <span>std</span>
                        </div>
                    </div>
                    <div className="house-card__start__body__v-line" />
                        </div> }

                {moduleSelected.toLowerCase() === "layers" &&
                <div className="house-card__end__dataSecondRow">
                    <div className="house-card__end__data__item">
                        <div className="house-card__end__data__item__data">
                            {data.feed_per_bird > data.st_feed_per_bird ? (
                                <b style={{ color: "#39db80" }}>{round10(data.feed_per_bird, -2)}</b>
                            ) : <b style={{ color: "red" }}>{round10(data.feed_per_bird, -2)}</b>
                            }
                            <span>Feed/Bird</span>
                        </div>
                        <div className="house-card__end__data__item__std">

                        <b>{round10(data.st_feed_per_bird, -2)}</b>
                            <span>std(gm)</span>
                        </div>
                    </div>
                    <div className="house-card__start__body__v-line" />
                        </div> }
                {data.house_manager?.length > 0 && (
                    <div className="house-card__end__foot">
                        <div className="house-card__end__foot__name"><UserSvg />{data.house_manager.length == 1 ? data.house_manager[0] : `${data.house_manager[0]} ...`}</div>
                    </div>
                )}
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {

})(HouseCard);