import Types from "../Types";
import requester from "../../../api/requester";


export const GET_OVERVIEW_DATA = (cum = false, getOverviewSync, clearGetOverviewData, getOverview) => {
    return async function (dispatch) {
        dispatch({
            type: Types.IS_GET_OVERVIEW_DATA_ERROR,
            payload: false,
        });
        await requester()
            .get(`/?cum=${cum}`)
            .then((response) => {
                if (response) {
                    if (response.data?.Today?.Hatchery?.needs_sync) {
                        getOverviewSync && getOverview && clearGetOverviewData && getOverviewSync(cum, clearGetOverviewData, getOverview)
                    } else {
                        dispatch({
                            type: Types.GET_OVERVIEW_DATA,
                            payload: response.data,
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error, "error message");
                dispatch({
                    type: Types.IS_GET_OVERVIEW_DATA_ERROR,
                    payload: true,
                });
            });
    };
};

export const CLEAR_GET_OVERVIEW_DATA = () => ({
    type: Types.CLEAR_GET_OVERVIEW_DATA,
});

export const GET_OVERVIEW_DATA_SYNC = (cum = false, clearOverview, getOverview) => {
    return async function (dispatch) {
        await requester()
            .get(`/sync/`)
            .then((response) => {
                clearOverview && clearOverview()
                getOverview && getOverview(cum)

            })
            .catch((error) => {
                console.log(error, "error message");
            });
    };
};

export const IS_GET_OVERVIEW_DATA_ERROR = value => ({
    type: Types.IS_GET_OVERVIEW_DATA_ERROR,
    payload: value
})