import _ from "lodash";

const sort = (points) => {
    return (
        _.sortBy(points, [
            function (point) {
                return point.y;
            },
        ]).reverse()
    )
}

const hatcheryColumnChartGenerator = (chart) => {
    let chartData = {
        series: [{
            name: 'Fertility',
            data: chart.fertility,
            color: "#3B465E"

        }, {
            name: 'Hatchability',
            data: chart.hatchability,
            color: "#39db80"

        }, {
            name: 'HOF',
            data: chart.hof,
            color: "#ffa500"

        }, {
            name: 'Standard hatchability',
            data: chart.std_hatchability,
            color: "#a71c1c"

        }],
        chart: {
            type: 'column',
            height: '350',
            borderRadius: '12',
            scrollablePlotArea: {
                minWidth: chart.fertility?.length * 40,
                scrollPositionX: 1,
                opacity: .7
            }
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: chart.labels,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: "%"
            }
        },
        tooltip: {
            headerFormat: '<b style="font-size:12px">{point.key}</b><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                `<td style="padding:0"><b>{point.y: 1f} %</b></td></tr>`,
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
            formatter: function () {
                return sort(this.points).reduce(function (s, point) {
                    return s + `<table> <tr> <td style="color:${point.series.color};padding:0;width:100%">` + point.series.name + ': </td>' +
                        '<td style="padding:0"><b>' + point.y + '% </b></td> </tr> </table>';
                }, '<b>' + this.x + '</b>');
            },
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        credits: {
            enabled: false,
        },
    }
    return chartData
}

export default hatcheryColumnChartGenerator;