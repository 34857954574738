import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import {
    SET_DAY_SELECTED,
    STOP_SCROLLING,
} from "../../redux/actions/appData/index"

import {
    GET_OVERVIEW_DATA_SYNC,
    GET_OVERVIEW_DATA,
    CLEAR_GET_OVERVIEW_DATA
} from "../../redux/actions/overView/index";

import { ReactComponent as NotificationSvg } from "../../assets/bell.svg";
import { ReactComponent as DaySvg } from "../../assets/24-hours.svg";
import { ReactComponent as CalenderSvg } from "../../assets/calendar.svg";
import { ReactComponent as RefreshSvg } from "../../assets/refresh-page-option.svg";
import { ReactComponent as MenuSvg } from "../../assets/menu.svg";
import { ReactComponent as GoBackToHomeSvg } from "../../assets/arrowBack.svg";

import NotificationDropDown from './NotificationDropDown';
import SideNavMenu from '../sideNavBar/SideNavMenu';
import DayPopUp from '../dayPopUp/DayPopUp';

function NavBar(props) {

    const [openSideNavMenu, setOpenSideNavMenu] = useState(false)


    //notification dropdown control
    const [handleOpenNotificDropDown, setHandleOpenNotificDropDown] = useState(false)

    const handleBlurNotificDropDown = () => {
        setTimeout(() => {
            setHandleOpenNotificDropDown(false)
        }, 250);
    }

    const updateTheData = () => {
        props.GET_OVERVIEW_DATA_SYNC(props.gstate.isComultive, props.CLEAR_GET_OVERVIEW_DATA, props.GET_OVERVIEW_DATA)
    }

    const mq = window.matchMedia("(max-width: 600px)");

    useEffect(() => {
        if (handleOpenNotificDropDown && mq.matches) {
            props.STOP_SCROLLING(true)
        } else {
            props.STOP_SCROLLING(false)
        }
    }, [handleOpenNotificDropDown])

    //day popup control
    const [isPopUpOpen, setIsPopUpOpen] = useState(false)

    useEffect(() => {
        if (isPopUpOpen) {
            props.STOP_SCROLLING(true)
        } else {
            props.STOP_SCROLLING(false)
        }
    }, [isPopUpOpen])


    return (
        <Fragment>
            <nav className="navBar">
                <div className="navBar__body general-container">
                    <div className="navBar__body__start">
                        {props.atDetails ? (
                            props.viwMobileChat ? (
                                    <div className="navBar__body__start__icon" onClick={() => props.setViwMobileChat(false)}>
                                        <GoBackToHomeSvg />
                                    </div>
                            ) : (
                                <Link to="/">
                                    <div className="navBar__body__start__icon">
                                        <GoBackToHomeSvg />
                                    </div>
                                </Link>
                            )
                        ) : (
                            <div className="navBar__body__start__icon" onClick={() => setOpenSideNavMenu(true)} id="tour-navbar-menu-btn">
                                <MenuSvg />
                            </div>
                        )}

                        {props.moduleName ? (
                            <div className="navBar__body__start__module" id="tour-module-name-at-navbat">{props.moduleName}</div>
                        ) : (
                            <div className="navBar__body__start__module" id="tour-module-name-at-navbat">{props.gstate?.moduleSelected}</div>
                        )}
                    </div>
                    <div className="navBar__body__end">
                        <div className="navBar__body__end__day-selected" onClick={() => setIsPopUpOpen(true)} id="tour-navBar-filtered-dayselected">
                            <span>{props.gstate.daySelected.name}</span>
                            {props.gstate.isComultive && <p>CUM.</p>}
                        </div>
                        <div className="navBar__body__end__day" id="tour-navBar-filters-options-icon">
                            {/* <button onBlur={handleBlurDayDropDown} onClick={() => setHandleOpenDayDropDown(!handleOpenDayDropDown)} className="navBar__body__end__day__icon"> */}
                            <button onClick={() => setIsPopUpOpen(true)} id="callender-button-open-filter-popup" className="navBar__body__end__day__icon">
                                <CalenderSvg />
                            </button>
                        </div>
                        
                        <div className="navBar__body__end__day-selected remove-on-900" onClick={() => window.location.reload()}>
                            <span style={{ "text-decoration" : "underline" }}>Refresh Data</span>
                        </div>

                        <div className="navBar__body__end__day" id="tour-navBar-filters-options-icon">
                            {/* <button onBlur={handleBlurDayDropDown} onClick={() => setHandleOpenDayDropDown(!handleOpenDayDropDown)} className="navBar__body__end__day__icon"> */}
                            <button onClick={() => window.location.reload()} id="callender-button-open-filter-popup" className="navBar__body__end__day__icon">
                                <RefreshSvg />
                            </button>
                        </div>
                        {/* <div className="navBar__body__end__notifications" id="tour-navbar-notification-icon" onClick={() => setHandleOpenNotificDropDown(!handleOpenNotificDropDown)}>
                            <button onBlur={handleBlurNotificDropDown} className="navBar__body__end__notifications__icon" id="notificationBillNavBarButton">
                                <NotificationSvg />
                            </button>
                            {handleOpenNotificDropDown ? <NotificationDropDown /> : null}
                            {props.gstate.notificationData?.length > 0 && (
                                <div className="navBar__body__end__notifications__count">{props.gstate.notificationData?.length}</div>
                            )}
                        </div> */}
                    </div>
                </div>
            </nav>
            {
                openSideNavMenu && (
                    <SideNavMenu setOpenSideNavMenu={setOpenSideNavMenu} openSideNavMenu={openSideNavMenu} />
                )
            }
            <DayPopUp isPopUpOpen={isPopUpOpen} setIsPopUpOpen={setIsPopUpOpen} />
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    SET_DAY_SELECTED,
    STOP_SCROLLING,
    GET_OVERVIEW_DATA_SYNC,
    GET_OVERVIEW_DATA,
    CLEAR_GET_OVERVIEW_DATA,
})(NavBar);