import React from 'react'

import { ReactComponent as NoInternetSvg } from "../../assets/no-wifi.svg";

function NoInternet() {
    return (
        <div className="no-internet">
            <div className="no-internet__icon"><NoInternetSvg /></div>
            <div className="no-internet__title">No Internet</div>
            <div className="no-internet__text">please check your Internet connection and try again</div>
        </div>
    )
}

export default NoInternet
