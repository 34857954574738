import React from 'react'
import { Fragment } from 'react'

function MessageCard({ send = false }) {
    return (
        send ? (
            <div className="emails__body__right__mid__send">
                <div className="emails__body__right__mid__send__body">
                    <div className="emails__body__right__mid__send__body__msg">
                        Lorem Ipsum is simply dummy text printing and typesetting industry is the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry
                </div>
                    <div className="emails__body__right__mid__send__body__date">
                        23/5/2021, 3:50pm
                </div>
                </div>
            </div>
        ) : (
            <div className="emails__body__right__mid__receive">
                <div className="emails__body__right__mid__receive__body">
                    <div className="emails__body__right__mid__receive__body__msg">
                        Lorem Ipsum is simply dummy text of the printing and printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry
                </div>
                    <div className="emails__body__right__mid__receive__body__date">
                        23/5/2021, 3:50pm
                </div>
                </div>
            </div>
        )
    )
}

export default MessageCard
