import Types from "../../actions/Types";

const userData = (state = null, action) => {
  switch (action.type) {
    case Types.GET_USER_DATA:
      return action.payload
    case Types.CLEAR_GET_USER_DATA:
      return null
    default:
      return state;
  }
};

export default userData;