import Types from "../../actions/Types";

const INITIAL_STATE = {
    loading: false,
    error: null
}

const readersSummaryUpdate = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.UPDATE_READERS_SUMMARY_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case Types.UPDATE_READERS_SUMMARY_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case Types.CLEAR_UPDATE_READERS_SUMMARY:
            return INITIAL_STATE
        default:
            return state;
    }
};

export default readersSummaryUpdate;