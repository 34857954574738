import Types from "../Types";
import requester from "../../../api/requester";


export const GET_READERS_SUMMARY = (moduleName, daySelected, isCum = false, type) => {
    return async function (dispatch) {
        dispatch({
            type: Types.IS_GET_DATA_ERROR,
            payload: false,
        });
        let isMonthly
        if(type == 'monthly') {
            isMonthly = true
        } else {
            isMonthly = false
        }
        await requester()
            .get(`/${moduleName.toLowerCase()}/card_summary/?date=${daySelected?.value.toLowerCase()}&cum=${isCum}&isMonthly=${isMonthly}`)
            .then((response) => {
                dispatch({
                    type: Types.GET_READERS_SUMMARY,
                    payload: response.data,
                });
            })
            .catch((error) => {
                console.log(error, "error message");
                dispatch({
                    type: Types.IS_GET_DATA_ERROR,
                    payload: true,
                });
            });
    };
};

export const CLEAR_GET_READERS_SUMMARY = () => ({
    type: Types.CLEAR_GET_READERS_SUMMARY,
});

export const GET_READERS_SUMMARY_CARDS = (moduleName = "") => {
    return async function (dispatch) {
        dispatch({
            type: Types.CLEAR_GET_READERS_SUMMARY_CARDS,
            payload: null,
        });
        dispatch({
            type: Types.GET_READERS_SUMMARY_CARDS_LOADING,
            payload: true,
        });
        await requester()
            .get(`${moduleName.toLowerCase()}/cards`)
            .then((response) => {
                dispatch({
                    type: Types.GET_READERS_SUMMARY_CARDS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                console.log(error, "error message");
                dispatch({
                    type: Types.GET_READERS_SUMMARY_CARDS_ERROR,
                    payload: true,
                });
            });
        dispatch({
            type: Types.GET_READERS_SUMMARY_CARDS_LOADING,
            payload: false,
        });
    };
};

export const GET_READERS_SUMMARY_CARDS_LOADING = data => ({
    type: Types.GET_READERS_SUMMARY_CARDS_LOADING,
    payload: data
})

export const GET_READERS_SUMMARY_CARDS_ERROR = data => ({
    type: Types.GET_READERS_SUMMARY_CARDS_ERROR,
    payload: data
})

export const CLEAR_GET_READERS_SUMMARY_CARDS = () => ({
    type: Types.CLEAR_GET_READERS_SUMMARY_CARDS,
});

export const GET_READERS_SUMMARY_EDIT_CARDS = (moduleName = "") => {
    return async function (dispatch) {
        dispatch({
            type: Types.CLEAR_GET_READERS_SUMMARY_EDIT_CARDS,
            payload: null,
        });
        dispatch({
            type: Types.GET_READERS_SUMMARY_EDIT_CARDS_LOADING,
            payload: true,
        });
        await requester()
            .get(`/cards?module=${moduleName.toLowerCase()}&level=farm`)
            .then((response) => {
                dispatch({
                    type: Types.GET_READERS_SUMMARY_EDIT_CARDS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                console.log(error, "error message");
                dispatch({
                    type: Types.GET_READERS_SUMMARY_EDIT_CARDS_ERROR,
                    payload: true,
                });
            });
        dispatch({
            type: Types.GET_READERS_SUMMARY_EDIT_CARDS_LOADING,
            payload: false,
        });
    };
};

export const GET_READERS_SUMMARY_EDIT_CARDS_LOADING = data => ({
    type: Types.GET_READERS_SUMMARY_EDIT_CARDS_LOADING,
    payload: data
})

export const GET_READERS_SUMMARY_EDIT_CARDS_ERROR = data => ({
    type: Types.GET_READERS_SUMMARY_EDIT_CARDS_ERROR,
    payload: data
})

export const CLEAR_GET_READERS_SUMMARY_EDIT_CARDS = () => ({
    type: Types.CLEAR_GET_READERS_SUMMARY_EDIT_CARDS,
});

export const UPDATE_READERS_SUMMARY = (moduleName = "", body, closeModal, getCards) => {
    return async function (dispatch) {
        dispatch({
            type: Types.UPDATE_READERS_SUMMARY_ERROR,
            payload: null,
        });
        dispatch({
            type: Types.UPDATE_READERS_SUMMARY_LOADING,
            payload: true,
        });
        await requester()
            .put(`cards/edit?module=${moduleName.toLowerCase()}`, body)
            .then((response) => {
                closeModal && closeModal();
                getCards && getCards(moduleName);
            })
            .catch((error) => {
                console.log(error, "error message");
                dispatch({
                    type: Types.UPDATE_READERS_SUMMARY_ERROR,
                    payload: true,
                });
            });
        dispatch({
            type: Types.UPDATE_READERS_SUMMARY_LOADING,
            payload: false,
        });
    };
};

export const UPDATE_READERS_SUMMARY_LOADING = data => ({
    type: Types.UPDATE_READERS_SUMMARY_LOADING,
    payload: data
})

export const UPDATE_READERS_SUMMARY_ERROR = data => ({
    type: Types.UPDATE_READERS_SUMMARY_ERROR,
    payload: data
})

export const CLEAR_UPDATE_READERS_SUMMARY = () => ({
    type: Types.CLEAR_UPDATE_READERS_SUMMARY
})

export const DELETE_READERS_SUMMARY_CARD = (moduleName = "", body, getCards) => {
    return async function (dispatch) {

        await requester()
            .put(`cards/edit?module=${moduleName.toLowerCase()}`, body)
            .then((response) => {
                getCards && getCards(moduleName);
            })
            .catch((error) => {
                console.log(error, "error message");
            });
    };
};




export const GET_READERS_SUMMARY_HOUSE_CARDS = (modules, date, farmId, isCum = false) => {
    return async function (dispatch) {
        dispatch({
            type: Types.CLEAR_GET_READERS_SUMMARY_HOUSE_CARDS,
        });
        dispatch({
            type: Types.GET_READERS_SUMMARY_HOUSE_CARDS_LOADING,
            payload: true,
        });
        await requester()
            .get(`/${modules.toLowerCase()}/farm/?date=${date?.toLowerCase()}&farm_id=${farmId}&cum=${isCum}`)
            .then((response) => {
                if (response.data?.data) {
                    dispatch({
                        type: Types.GET_READERS_SUMMARY_HOUSE_CARDS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error, "error message");
                dispatch({
                    type: Types.GET_READERS_SUMMARY_HOUSE_CARDS_ERROR,
                    payload: true,
                });
            });
        dispatch({
            type: Types.GET_READERS_SUMMARY_HOUSE_CARDS_LOADING,
            payload: false,
        });
    };
};

export const GET_READERS_SUMMARY_HOUSE_CARDS_LOADING = data => ({
    type: Types.GET_READERS_SUMMARY_HOUSE_CARDS_LOADING,
    payload: data
})

export const GET_READERS_SUMMARY_HOUSE_CARDS_ERROR = data => ({
    type: Types.GET_READERS_SUMMARY_HOUSE_CARDS_ERROR,
    payload: data
})

export const CLEAR_GET_READERS_SUMMARY_HOUSE_CARDS = () => ({
    type: Types.CLEAR_GET_READERS_SUMMARY_HOUSE_CARDS,
});

export const GET_READERS_SUMMARY_HOUSE_EDIT_CARDS = (moduleName = "") => {
    return async function (dispatch) {
        dispatch({
            type: Types.CLEAR_GET_READERS_SUMMARY_HOUSE_EDIT_CARDS,
        });
        dispatch({
            type: Types.GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_LOADING,
            payload: true,
        });
        await requester()
            .get(`/cards?module=${moduleName.toLowerCase()}&level=house`)
            .then((response) => {
                dispatch({
                    type: Types.GET_READERS_SUMMARY_HOUSE_EDIT_CARDS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                console.log(error, "error message");
                dispatch({
                    type: Types.GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_ERROR,
                    payload: true,
                });
            });
        dispatch({
            type: Types.GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_LOADING,
            payload: false,
        });
    };
};

export const GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_LOADING = data => ({
    type: Types.GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_LOADING,
    payload: data
})

export const GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_ERROR = data => ({
    type: Types.GET_READERS_SUMMARY_HOUSE_EDIT_CARDS_ERROR,
    payload: data
})

export const CLEAR_GET_READERS_SUMMARY_HOUSE_EDIT_CARDS = () => ({
    type: Types.CLEAR_GET_READERS_SUMMARY_HOUSE_EDIT_CARDS,
});

export const UPDATE_READERS_SUMMARY_HOUSE = (moduleName = "", body, closeModal, getCards) => {
    return async function (dispatch) {
        await requester()
            .put(`cards/edit?module=${moduleName.toLowerCase()}`, body)
            .then((response) => {
                closeModal && closeModal();
                getCards && getCards();
            })
            .catch((error) => {
                console.log(error, "error message");
            });
    };
};

export const DELETE_READERS_SUMMARY_HOUSE_CARD = (moduleName = "", body, getCards) => {
    return async function (dispatch) {
        await requester()
            .put(`cards/edit?module=${moduleName.toLowerCase()}`, body)
            .then((response) => {
                getCards && getCards();
            })
            .catch((error) => {
                console.log(error, "error message");
            });
    };
};