import React from 'react'

function NewMsgTab(props) {
    return (
        <div className="emails__body__left__new-msg-tab fade-in-from-top">
            <div className="emails__body__left__new-msg-tab__title">Search for user</div>
            <div className="emails__body__left__new-msg-tab__input">
                <input type="text" placeholder="Type user name" />
            </div>
            <div className="emails__body__left__new-msg-tab__users">
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
                <div className="emails__body__left__new-msg-tab__users__user" onClick={()=>props.setOpenNewMsgTab()}>mabdo@abuerdan.com</div>
            </div>
        </div>
    )
}

export default NewMsgTab
