import Types from "../Types";
import requester from "../../../api/requester";


export const GET_FARM_DETAILS = (modules, date, farmId, isCum = false) => {
    return async function (dispatch) {
        dispatch({
            type: Types.IS_GET_DATA_ERROR,
            payload: false,
        });
        dispatch({
            type: Types.GET_READERS_SUMMARY_HOUSE_CARDS_LOADING,
            payload: true,
        });
        await requester()
            .get(`/${modules.toLowerCase()}/farm/?date=${date?.toLowerCase()}&farm_id=${farmId}&cum=${isCum}`)
            .then((response) => {
                if(response.data) {
                    dispatch({
                        type: Types.GET_FARM_DETAILS,
                        payload: response.data,
                    });

                    if(response.data.data) {
                        dispatch({
                            type: Types.GET_READERS_SUMMARY_HOUSE_CARDS,
                            payload: response.data.data,
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error, "error message");
                dispatch({
                    type: Types.IS_GET_DATA_ERROR,
                    payload: true,
                });
            });
            dispatch({
                type: Types.GET_READERS_SUMMARY_HOUSE_CARDS_LOADING,
                payload: false,
            });
    };
};

export const CLEAR_GET_FARM_DETAILS = () => ({
    type: Types.CLEAR_GET_FARM_DETAILS,
});