import React from 'react'

function HistoryEmailCard(props) {
    
    const clickHandler = () => {
        props.setViwMobileChat && props.setViwMobileChat(true)
        
    }

    return (
        <div className="emails__body__left__msg" onClick={clickHandler}>
            <div className="emails__body__left__msg__top">
                <div className="emails__body__left__msg__top__name">Mahmoud Abdo</div>
                <div className="emails__body__left__msg__top__time">3h ago</div>
            </div>
            <div className="emails__body__left__msg__bot">
                <div className="emails__body__left__msg__bot__msg two-lines">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry
                </div>
            </div>
        </div>
    )
}

export default HistoryEmailCard
