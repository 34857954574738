import millify from 'millify';

const milifyNumber = (num) => {
    return millify(num, {
        precision: 2,  
        decimalSeparator: '.'
      });
}

const broilersCostBarChartDataGenerator = (chart) => {
    let chartData = {
        series: [{
            name: "Sales amount",
            data: chart.sales_amount,
            color: "#3B465E"

        }, {
            name: "Total cost",
            data: chart.total_cost,
            color: "#39db80"

        }],
        chart: {
            type: 'column',
            height: '350',
            borderRadius: '12',
            scrollablePlotArea: {
                minWidth: chart.labels?.length*40,
                scrollPositionX: 1,
                opacity: .7
            }
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: chart.labels,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ""
            }
        },
        tooltip: {
            headerFormat: '<b style="font-size:12px;margin:10px 0">{% trans "Day" %} {point.key}</b><table>',
            pointFormatter: function() {
            return '<tr><td style="color:{series.color};padding:0">'+this.series.name+ ': </td>' +
            '<td style="padding:0"><b>'+ (this.y && milifyNumber(this.y)) + " " +'</b></td></tr>'
            },
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        credits: {
            enabled: false,
        },
    }
    return chartData
}

export default broilersCostBarChartDataGenerator;