import Highcharts from 'highcharts';
import darkTheme from "./helpers/chartsDarkColors";

//** Dark&Light Mode **/
let darkMode = false;

if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && !localStorage.getItem("theme")) {
    localStorage.setItem("theme", "dark")
    darkMode = true;
} else if(localStorage.getItem("theme") === "dark") {
    darkMode = true;
} else {
    darkMode = false;
}

Highcharts.setOptions(darkMode && darkTheme);
//****/

const render = () => {
    if (!darkMode) {
        import("./sass/_antd.less").then(() => import("./sass/main.scss")).then(() => {
            require("./AppRender");
        });
    } else {
        import("./sass/_antdDark.less").then(() => import("./sass/mainDark.scss")).then(() => {
            require("./AppRender");
        });
    }
};

render();
